.contact {
  .offices {
    padding: 150px 0;
    color: $white;

    @media (max-width: 700px) {
      padding: 80px 0;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 70px;
      justify-items: center;

      @media (max-width: 900px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 700px) {
        grid-template-columns: 1fr;
      }
    }

    &__short-name {
      text-transform: uppercase;
      font-weight: $extra-bold;
      font-size: 30px;
      margin-bottom: 10px;

      @media (max-width:1400px) {
        font-size: 24px;
      }
    }

    &__city {
      font-size: 26px;
      letter-spacing: 1px;

      @media (max-width:1400px) {
        font-size: 20px;
      }
    }

    &__map {
      margin-top: 70px;
      display: flex;
      color: $white;
      font-size: 14px;
      align-items: center;
      font-weight: $bold;

      @media (max-width: 700px) {
        margin-top: 40px;
      }

      img {
        width: 20px;
        height: auto;
        margin-right: 15px;
      }
    }

    &__office {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: -25px;
        top: 25px;
        background-image: url("/assets/images/theme/shapes/rectangle.svg");
        width: 200px;
        height: 50px;
        z-index: 0;
        transform: rotate(-15deg);
        transition: 0.5s;
      }

      * {
        z-index: 1;
        position: relative;
      }

      &:hover {
        &::after {
          width: 220px;
          height: 55px;
        }
      }
    }

    &__first {
      &::after {
        transform: rotate(-15deg);
      }
    }

    &__second {
      &::after {
        transform: rotate(30deg);
      }
    }

    &__third {
      &::after {
        transform: rotate(-50deg);
      }
    }
  }
}
