.nav {
  padding: 30px 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  transform: translateY(0);
  transition: transform 0.3s;

  #nav__logo-open {
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
    display: none;

    @media (max-width: 800px) {
      display: none;
      width: 0;
    }
  }

  #nav__logo--scroll {
    display: none;
  }

  @media (max-width: 500px) {
    padding-top: 15px;
  }

  &__container {
    display: grid;
    grid-template-columns: 25% 50% 12% 10%;
    align-items: center;

    @media (max-width: 1200px) {
      grid-template-columns: 33% 33% 16% 18%;
    }

    @media (max-width: 800px) {
      grid-template-columns: 50% 50%;
      align-items: center;
    }
  }

  &__logo {
    @include user-select-none;

    img {
      width: 140px;
      height: auto;
      display: block;
      transition: 0.5s;

      @media (max-width: 500px) {
        width: 100px;
      }
    }
  }

  &__languages {
    text-align: right;
    grid-column-start: 3;
    grid-column-end: 4;

    &-link {
      font-size: 12px;
      font-weight: $regular;
      padding: 15px;
      transition: 0.5s;
      @include user-select-none;

      &--active {
        border: 1px solid $black;
        border-radius: 666px;
      }
    }

    @media (max-width: 800px) {
      display: none;
    }
  }

  &__career {
    position: absolute;
    top: 25px;
    right: -20px;
    transform: rotate(-30deg);
    transition: 0.5s;

    &--link {
      color: $white;
      background-color: $main;
      font-weight: $extra-bold;
      font-size: 15px;
      padding: 15px 30px 15px 20px;
      transition: 0.3s;

      &:hover {
        padding: 20px 35px 23px 20px;
      }
    }

    @media (max-width: 800px) {
      display: none;
    }
  }

  &__hamburger {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 45px;
    z-index: 999;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    @media (max-width: 800px) {
      left: auto;
      right: 0;
    }

    @media (max-width: 500px) {
      row-gap: 8px;
      width: 40px;
      top: 15px;
    }

    &-line {
      display: block;
      width: 100%;
      height: 3px;
      background-color: $white;
      transition: all 0.3s ease-in-out;
    }
  }

  &__items {
    transition: all 0.3s linear;
    position: absolute;
    background-color: rgba(255, 255, 255, 90%);
    top: 0;
    left: 0;
    right: 0;
    padding: 50px 0 50px 50px;
    border-radius: 0 0 100%;
    box-shadow: -40px 30px 0 10px $main;
    display: flex;
    flex-direction: column;
    row-gap: 3vh;
    transform: translate(-100%, -100%);
    z-index: 998;
    height: 60vh;
    overflow: auto;

    @media (max-width: 1400px){
      padding: 30px 0 30px 50px;
      height: 70vh;
    }
  }

  &__item {
    color: $dark;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    position: relative;

    @media (max-width: 1400px){
      font-size: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 4px;
      background-color: $main;
      transition: transform 0.2s ease-in-out;
      transform-origin: center right;
      transform: scaleX(0);
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: center left;
    }

    &-language {
     & > * {
      border-color: $black!important;
      color: $black!important;
     }
    }
  }

  &--dark {
    background-color: transparent;
    color: $black;

    #nav__icon,
    #nav__icon::before,
    #nav__icon::after {
      background: $black;
    }
  }

  &--light {
    background-color: transparent;
    color: $white;

    .nav__languages-link {
      color: $white;
      font-weight: $bold;
    }

    .nav__languages-link--active {
      border-color: $white;
    }
  }

  &__icon {
    &-menu,
    &-close {
      position: absolute;
      right: 70px;
      top: -5px;
      font-size: 12px;
      font-weight: $extra-bold;
      @include user-select-none;
    }

    &-close {
      display: none;
    }

    &-menu {
      @media (max-width: 800px) {
        display: none;
      }
    }
  }
}

#nav__icon {
  display: inline-block;
  margin: 10px 0;
  width: 50px;
  position: relative;
  transition: 0.5s;

  &,
  &::before,
  &::after {
    background: $white;
    height: 4px;
    transition: all 0.2s ease-in-out;
    width: 50px;
    border-radius: 0;
  }

  &::before,
  &::after {
    content: "";
    left: 0;
    position: absolute;
  }

  &::before {
    top: -0.4em;
  }

  &::after {
    bottom: -0.4em;
  }
}

.nav--scroll {
  height: 40px;
  transition: all 0.5s;
  background-color: rgba($white, 0.9);
  padding: 10px 0;

  @media (max-width: 800px) {
      height: 20px;
    }

  .nav__logo img {
    width: 110px;

    @media (max-width: 800px) {
      width: 70px;
    }
  }

  #nav__icon,
  #nav__icon::before,
  #nav__icon::after {
    height: 2px;
    background: $black;
  }

  .nav__languages {
    margin-top: -5px;
  }

  .nav__languages-link {
    padding: 8px;
    color: $black;
    font-size: 12px;

    &--active {
      border-width: 1px;
      border-color: $black;
    }
  }

  .nav__career {
    transform: rotate(0deg);
    position: initial;
    margin-left: 20px;
    margin-top: -7px;
  }

  .nav__career--link {
    padding: 10px;
    font-size: 14px;
    background-color: $main;
    color: $white;
  }

  .nav__hamburger {
    row-gap: 7px;
    top: 12px;

    &-line {
      background-color: $dark;
    }
  }
}

.nav--top {
  transition: 0.5s;
  background-color: transparent;
}

#nav__languages,
#nav__career {
  @media (max-width: 800px) {
    visibility: hidden;
  }
}

.nav.nav--scroll {
  & .nav__hamburger {
    @media (max-width: 800px) {
      top: 10px;
      width: 35px;
      row-gap: 6px;
    }
  }
}

.nav.nav--dark {
  & .nav__hamburger {
    &-line {
      background-color: $dark;
    }
  }
}

.nav.active,
.nav.active.nav--scroll {
  transform: translateY(-200px);

  & .nav__hamburger {
    top: calc(40vh + 200px);
    background-color: $main;
    padding: 10px;
    border-radius: 666px;
    transform: translateX(-50%);
    row-gap: 0;
    width: 40px;
    height: 40px;

    @media (max-width: 800px) {
      top: calc(75vh + 200px);
      right: 50%;
      transform: translateX(50%);
    }

    &-line {
      background-color: $white;

      &-middle {
        opacity: 0;
      }

      &-top {
        width: 40px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }

      &-bottom {
        width: 40px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  & .nav__items {
    transition: all 0.3s linear;
    transform: translate(0, 200px);
  }
}

main {
  transition: all 0.3s;
}

main.blur {
  filter: blur(10px);
  transition: all 0.3s;
}
