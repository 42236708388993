.portfolio  {
  .photos {
    padding: 40px 0;

    &__container {
      display: grid;
      grid-template-areas:
        "one one two  three"
        "one one four five ";
      gap: 10px;

      @media (max-width: 900px) {
        grid-template-areas:
          "one  one"
          "two  three"
          "four five";
      }

      img {
        border-radius: 20px;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    &__one {
      grid-area: one;
    }

    &__two {
      grid-area: two;
    }

    &__three {
      grid-area: three;
    }

    &__four {
      grid-area: four;
    }

    &__five {
      grid-area: five;
    }
  }
}
