.form {
  &__group {
    margin: 20px 10px;
  }

  &__label {
    color: $white;
    font-size: 20px;
    margin-left: 20px;

    span {
      color: $main;
    }
  }

  input,
  textarea {
    background-color: transparent;
    color: $white;
    outline: none;
    border: none;
    border-bottom: 3px solid $white;
    width: 100%;
    margin-top: 10px;
    font-size: 20px;
  }

  textarea {
    resize: vertical;
  }

  &__acceptance {
    color: $light-gray;
    font-size: 16px;

    a {
      color: $light-gray;
      text-decoration: underline;

      &:hover {
        color: $main;
      }
    }

    label {
      display: flex;
      align-items: baseline;

      &:hover {
        cursor: pointer;
      }
    }

    input {
      margin-top: 0;
      width: auto;
      margin-right: 10px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: $bold;
    margin: 30px 0;
    padding: 15px 40px;
  }

  &__arrow {
    height: 30px;
    width: auto;
    transform: rotate(-90deg);
    margin-left: 25px;
  }
}

.contact {
  & .form {

    @media (max-width: 1400px) {
      &__label {
        font-size: 14px;
      }

      &__acceptance {
        font-size: 14px;
      }

      input,
      textarea {
        font-size: 16px;
      }
    }
  }
}
