.about {
  .values-mobile {
    display: none;

    @media (max-width: 1000px) {
      display: block;
      padding: 50px 0;
      background-color: $black;
      position: relative;

      .container {
        max-width: 80%;
      }

      &::after {
        content: '';
        position: absolute;
        background-color: $darker-gray;
        right: 0;
        transform: translateX(40%);
        top: 15%;
        width: 500px;
        height: 500px;
        border-radius: 666px;
      }

      &__row {
        display: flex;
        position: relative;
        z-index: 2;

        &-one {
          margin-left: auto;
          margin-bottom: -150px;
          width: 35%;

          @media (max-width: 500px) {
            margin-bottom: -100px;
          }
        }

        &-two {
          align-items: flex-end;
          margin-right: 5%;
          margin-left: -30px;
          column-gap: 5%;

          @media (max-width: 500px) {
            margin-left: 0;
          }
        }

        &-three {
          margin-left: 35%;
          width: 40%;
          margin-top: -30px;

          @media (max-width: 500px) {
            margin-top: -20px;
          }
        }

        &-four {
          margin-top: -30px;
          justify-content: space-between;

          @media (max-width: 500px) {
            margin-top: 0;
          }
        }

        &-five {
          margin-top: -30px;
          justify-content: space-between;
          align-items: flex-end;

          @media (max-width: 500px) {
            margin-top: 0;
          }
        }
      }

      &__one {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -50px;
          width: 35px;
          height: 35px;
          background-color: $main;
          border-radius: 666px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -30px;
          right: 0;
          width: 25px;
          height: 25px;
          background-color: $main;
          border-radius: 666px;
        }
      }

      &__two {
        transform: scale(0.75);
      }

      &__five {
        width: 49%;
      }

      &__six {
        width: 42%;
      }

      &__seven {
        width: 42%;
      }

      &__eight {
        width: 49%;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -10px;
          width: 35px;
          height: 35px;
          background-color: $main;
          border-radius: 666px;

          @media (max-width: 500px) {
            top: -30px
          }
        }
      }
    }
  }
}
