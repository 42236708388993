.services {
  .team-augmentation {
    padding: 130px 0;

    @media (max-width: 1200px) {
      padding: 100px 0;
    }

    @media (max-width: 1000px) {
      padding: 50px 0;
    }

    &__heading h2 {
      position: relative;
      font-size: 50px;
      font-weight: $bold;
      text-transform: uppercase;
      z-index: 0;

      @media (max-width: 1300px) {
        font-size: 35px;
      }

      @media (max-width: 900px) {
        font-size: 28px;
      }

      @media (max-width: 500px) {
        font-size: 22px;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        background-image: url('/assets/images/theme/shapes/pentagon-orange.svg');
        background-repeat: no-repeat;
        top: -110px;
        left: -110px;
        width: 339px;
        height: 333px;
        transform: scale(0.7);

        @media (max-width: 1400px) {
          transform: scale(0.6);
        }

        @media (max-width: 1200px) {
          transform: scale(0.5);
        }

        @media (max-width: 600px) {
          transform: scale(0.4);
          top: -150px;
          left: -130px;
        }
      }
    }

    &__wrapper {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: 35% 30% 35%;
      gap: 20px;
      grid-template-areas:
        "heading .         img-one"
        "txt     img-three shape"
        "txt     img-three img-two";

      @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto) 20% 1fr 15%;
        gap: 10px;
        grid-template-areas:
          "heading   heading"
          "txt       txt"
          "img-three img-one"
          "img-three shape"
          ".         img-two";
      }
    }

    &__heading {
      display: flex;
      align-items: center;
      grid-area: heading;
      position: relative;
      margin-right: -340px;
      @media (max-width: 1000px) {
        margin-right: 0;
      }
    }

    &__img-one {
      grid-area: img-one;
    }

    &__img-two {
      grid-area: img-two;
    }

    &__img-three {
      grid-area: img-three;
    }

    &__text {
      grid-area: txt;
      font-size: 24px;
      color: $light-gray;

      @media (max-width: 1300px) {
        font-size: 20px;
      }

      @media (max-width: 500px) {
        font-size: 18px;
      }
    }

    &__shape {
      grid-area: shape;
      display: grid;
      place-items: center;
    }

    &__shape-img {
      width: 65px;

      @media (max-width: 600px) {
        width: 35px;
      }
    }

    &__photo {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
}
