.portfolio {
  .aim {
    &__container {
      padding: 50px 0;
    }

    &__heading {
      @include section-heading;

      color: $black;
    }

    &__text {
      margin-top: 20px;
      color: $light-gray;

      @media (max-width: 1400px) {
        font-size: 20px;
      }

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }
  }
}
