.clients {
  padding: 50px 0;
  z-index: 1;
  background-color: $white;
  position: relative;

  &__container {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 30px;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  &__heading {
    font-size: 32px;
  }

  &__logo {
    max-height: 30px;
    width: 100%;

    @media (max-width: 600px) {
      width: 80%;
    }
  }

  &__slider {
    .splide__slide {
      display: grid;
      justify-content: center;
      align-self: center;
    }
  }
}
