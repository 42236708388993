.homepage{
  .benefits {
    padding: 80px 0;
    overflow: hidden;

    @media (max-width: 1800px) {
      padding: 50px 0;
    }

    &__sub-heading {
      @include section-sub-heading;

      font-size: 16px;
      color: $light-gray;

      @media (max-width: 500px) {
        font-size: 12px;
      }
    }

    &__item {
      display: inline;
      font-size: 40px;
      font-weight: $extra-bold;
      text-transform: uppercase;
      margin-right: 20px;

      @media (max-width: 800px) {
        font-size: 22px;
      }

      @media (max-width: 500px) {
        font-size: 16px;
      }
    }

    &__sliders {
      padding: 40px 0;

      @media (max-width: 500px) {
        padding: 10px 0;
      }
    }

    .splide__slide {
      &:nth-child(odd){
        .benefits__item {
          color: $main;
        }
      }
    }

    &__dot {
      text-align: center;
      color: $main;

      @media (max-width: 800px) {
        display: none;
      }
    }
  }
}
