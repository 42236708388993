.about {
  .team {
    padding: 100px 0;
    background: $coffee;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 700px;
    transition: all 0.3s linear;

    @media (max-width: 500px) {
      padding: 50px 0;
      background-size: 450px;
    }

    &.active {
      background-image: url("/assets/images/theme/shapes/white-circle.svg");
    }

    &__container {
      max-width: 1920px;
      margin: 0 auto;
    }

    &__heading {
      text-align: center;
      font-size: 36px;
      font-weight: $bold;
      line-height: 1;

      @media (max-width: 800px) {
        font-size: 28px;
      }
    }

    &__item {
      text-align: center;
      display: grid;
      justify-content: center;
      padding: 30px 0;

      &.active {
        & .team__item-wrapper {
          height: 510px;
          overflow-y: auto;
          overflow-x: hidden;
          scrollbar-width: thin;
          scrollbar-color: $coffee transparent;

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $coffee;
            border-radius: 666px;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          @media (max-width: 1300px) {
            height: 370px;
          }

          @media (max-width: 500px) {
            height: 300px;
          }
        }

        & .team__photo-wrapper{
          display: none;
        }

        & .team__name-wrapper {
          opacity: 0;
          transform: translateX(100%);
          z-index: 999;
          transition: all 0.2s;
        }

        & .team__description {
          display: block;
        }

        & .team__name-wrapper-alternative {
          display: block;
        }
      }
    }

    &__name-wrapper-alternative {
      display: none;
      max-width: 400px;
    }

    &__description {
      display: none;
      padding-top: 30px;
      font-size: 16px;
      max-width: 400px;

      @media (max-width: 1000px) {
        max-width: 250px;
        font-size: 14px;
      }

      @media (max-width: 500px) {
        font-size: 12px;
      }
    }

    &__item-wrapper {
      position: relative;

      &:hover {
        .team__photo {
          display: none;
        }

        .team__photo--hover {
          display: block;
        }

        .team__photo-wrapper::after {
          transform: rotate(-5deg)!important;
        }
      }
    }

    &__name-wrapper {
      position: absolute;
      bottom: 10%;
      left: -10%;
      text-align: left;
      font-family: $heading;
      opacity: 1;
      transition: all 0.4s ease-out;
      transform: translate3d(0, 0, 0);

      @media (max-width: 500px) {
        left: 20px;
      }
    }

    &__photo,
    &__photo--hover {
      max-width: 350px;
      border-radius: 666px;
      margin-top: 20px;
      transition: 0.3s;
      position: relative;
      transform: translate3d(0, 0, 0);

      @media (max-width: 1300px) {
        max-width: 250px;
      }

      @media (max-width: 500px) {
        max-width: 200px;
      }

      &::after {
        content: "aaa";
        width: 200px;
        height: 200px;
      }
    }

    &__photo--hover {
      display: none;
      transform: rotate(-5deg);
    }

    &__photo-wrapper {
      position: relative;

      &::after{
        bottom: -3%;
        right: -5%;
        background-color: #d6d6d6;
        position: absolute;
        width: 90%;
        height: 90%;
        content: "";
        z-index: -1;
        border-radius: 666px;
      }
    }

    &__name {
      background-color: $main;
      color: $white;
      font-size: 28px;
      font-weight: $extra-bold;
      padding: 10px 20px;
      margin-left: -20px;

      @media (max-width: 700px) {
        font-size: 22px;
        padding: 5px 10px;
      }
    }

    &__name-alternative {
      font-weight: $bold;
      font-size: 22px;
    }

    &__position {
      background-color: $black;
      color: $white;
      font-size: 18px;
      font-weight: $bold;
      padding: 10px 20px;

      @media (max-width: 700px) {
        font-size: 14px;
        padding: 5px 10px;
        display: table;
      }
    }

    &__position-alternative {
      font-weight: $bold;
      font-size: 16px;
    }

    &__navigation {
      font-size: 35px;
      font-weight: $extra-bold;
      visibility: hidden;
      position: absolute;
      top: 0;
      left: -100px;

      @media (max-width: 500px) {
        left: -40px;
        font-size: 26px;
      }
    }

    .is-active {
      .team__navigation {
        visibility: visible;
      }

      .team__photo {
        transform: rotate(10deg);
      }

      .team__photo-wrapper {
        &::after {
          transform: rotate(10deg);
        }
      }

      .team__button {
        visibility: visible;
      }
    }

    &__button {
      font-size: 16px;
      font-weight: $bold;
      color: $black;
      border: 2px solid $black;
      border-radius: 666px;
      display: inline-flex;
      align-items: center;
      padding: 15px 22px;
      width: fit-content;
      text-transform: uppercase;
      margin: 40px auto 0;
      visibility: hidden;
      background-color: transparent;
      cursor: pointer;

      @media (max-width: 500px) {
        padding: 10px 15px;
      }

      img {
        pointer-events: none;
        width: 15px;
        height: auto;
      }
    }

    .splide__slide:not(.is-active) {
      .team__item {
        transform: scale(0.80);
        transition: all 0.3s;
      }
    }

    .splide__slide.is-active {
      .team__item {
        transition: all 0.3s;
      }
    }

    .splide__arrow  {
      background: none;
      outline: none;
      border: none;
      font-size: 32px;
      font-weight: $bold;
      position: absolute;
      top: 45%;
      transform: translateY(-50%);
      z-index: 5;
    }

    &__arrow {
      width: 50px;
      height: auto;

      &:hover {
        cursor: pointer;
      }
    }

    .splide__arrow--prev {
      left: calc(50% - 330px);

      @media (max-width: 1300px) {
        left: calc(50% - 250px);
      }

      @media (max-width: 1000px) {
        left: calc(50% - 200px);
      }

      .team__arrow {
        transform: rotate(90deg);
      }
    }

    .splide__arrow--next {
      right: calc(50% - 330px);

      @media (max-width: 1300px) {
        right: calc(50% - 250px);
      }

      @media (max-width: 1000px) {
        right: calc(50% - 200px);
      }

      .team__arrow {
        transform: rotate(-90deg);
      }
    }
  }
}
