.homepage {
  $photos-opacity: 0.5;

  .about {
    background-color: $light-black;
    padding: 150px 0 100px;
    color: $white;
    position: relative;
    overflow: hidden;

    @media (max-width: 800px) {
      padding: 50px 0;
    }

    &__container {
      position: inherit;
      z-index: 1;
    }

    &__grid {
      display: grid;
      grid-template-columns: 40% 20% 10% auto;
      grid-template-rows: 100px auto 300px;
      grid-template-areas:
      "heading     one   one   two"
      "description one   one   two"
      "description shape three three";
      gap: 20px;

      @media (max-width: 1800px){
        grid-template-columns: 45% 15% 10% auto;
      }

      @media (max-width: 1000px) {
        grid-template-rows: 100px auto auto 300px;
        grid-template-areas:
        "heading     .             .             ."
        "one         one           two           two"
        "description description   description   description"
        "shape       three         three         three";
      }

      @media (max-width: 1000px) {
        gap: 10px;
      }
    }

    &__header {
      grid-area: heading;
    }

    &__heading {
      @include section-heading;
    }

    &__sub-heading {
      @include section-sub-heading;
    }

    &__button{
      text-align: right;

      @media (max-width: 600px) {
        text-align: left;
      }

      .button-empty {
        transition: 0.3s;

        @media (max-width: 900px) {
          padding: 15px 30px;
          border: 2px solid #fff;
          font-size: 16px;
        }

        &:hover {
          background-color: $main;
          border-color: $main;
        }
      }
    }

    &__text {
      font-size: 20px;
      font-weight: $regular;
      grid-area: description;
      max-width: 80%;

      @media (max-width: 1000px) {
        max-width: 100%;
      }

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    &__shape {
      grid-area: shape;
      display: grid;
      place-items: center;

      &-icon {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 1000px) {
          position: static;
          transform: translateX(0);
        }
      }
    }

    &__shape-img {
      width: 65px;

      @media (max-width: 600px) {
        width: 35px;
      }
    }

    &__image-one {
      grid-area: one;
    }

    &__image-two {
      grid-area: two;
    }

    &__image-three {
      grid-area: three;
    }

    &__photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }

    &__hr {
      margin: 30px 0;
      border: none;
      height: 3px;
      color: $white;
      background-color: $white;
    }

    &__work {
      display: grid;
      grid-template-columns: 60fr 40fr;
      align-items: center;
      justify-items: flex-end;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        justify-items: flex-start;
      }

      &-image {
        height: 175px;
        width: 175px;
      }

      .button-main {
        font-size: 20px;

        &:hover {
          background-color: $white;
          color: $black;
        }

        @media (max-width: 900px) {
          font-size: 16px;
        }
      }
    }

    &__work-in-akra{
      font-size: 22px;

      @media (max-width: 600px) {
        font-size: 18px;
      }

      &--bigger {
        font-size: 26px;
        font-weight: 700;

        @media (max-width: 600px) {
          font-size: 20px;
        }
      }

      .circle {
        position: relative;
        font-weight: 700;

        &::before {
          content: '';
          position: absolute;
          background: url('../images/theme/shapes/highlight.svg');
          background-repeat: no-repeat;
          background-size: 100%;
          width: 100%;
          height: 60px;
          z-index: -1;
        }
      }
    }

    &__animated-svg {
      width: 210px;
      height: 210px;

      @media (max-width: 1800px) {
        width: 140px;
        height: 140px;
      }

      @media (max-width: 1000px) {
        width: 210px;
        height: 210px;
      }

      @media (max-width: 600px) {
        width: 140px;
        height: 140px;
      }

      & .spin {
        transform-origin: center;
        animation: 35s rounded-text linear infinite;
      }
    }

    @keyframes rounded-text {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(359deg);
      }
    }
  }
}
