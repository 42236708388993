.content {
  overflow-wrap: anywhere;

  a {
    display: inline-block;
    word-break: break-word;
    position: relative;
    color: $main;
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $main;
      transform-origin: bottom right;
      transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px 0;
  }

  h1,
  h2 {
    font-size: 28px;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }

  h3 {

    @media (max-width: 800px) {
      font-size: 18px;
    }
  }

  h4 {

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }

  h5 {

    @media (max-width: 800px) {
      font-size: 14px;
    }
  }


  p,
  ul li,
  ol li {
    font-size: 18px;
  }

  p {
    margin: 10px 0;
  }

  ul li {
    list-style: none;
    position: relative;
    padding-left: 40px;
    margin: 20px 0;

    &::before {
      content: '';
      background-image: url("/assets/images/theme/shapes/arrow-red.svg");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: 8px;
      width: 20px;
      height: 15px;
    }
  }

  ol {
    padding-left: 20px;
  }

  blockquote {
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    border-left: 5px solid $main;
    font-style: italic;
  }

  pre.highlight {
    font-size: 14px;
    border-radius: 10px;
  }

  .highlight {
    display: grid;
  }

  table {
    border-collapse: collapse;
    border: 1px solid $dark-gray;
    width: 100%;
  }

  th,
  td {
    border: 1px solid $dark-gray;
    font-size: 12px;
    padding: 3px;
  }
}
