.portfolio-loop {
  padding: 25px 0 70px;

  @media (max-width: 800px) {
    padding: 25px 0 50px;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }

  &__image-container {
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    &:hover {
      .portfolio-loop__image {
        transform: scale(1.1);
      }
    }
  }

  &__image {
    object-fit: cover;
    aspect-ratio: 2/1;
    object-position: center;
    transition: .3s ease-in;
    display: block;
  }

  &__tag {
    position: absolute;
    bottom: 30px;
    right: 20px;
    background-color: $white;
    border: 1px solid $black;
    padding: 5px 10px;
    border-radius: 666px;
    font-size: 14px;
    font-weight: $extra-bold;
    transition: 0.3s;

    &:hover {
      color: $main;
    }
  }

  &__title {
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: $extra-bold;
    font-family: $text;
    transition: 0.3s;

    @media (max-width: 600px) {
      font-size: 20px;
    }

    &:hover {
      color: $main;
    }
  }

  &__arrow {
    height: 30px;
    width: auto;

    @media (max-width: 600px) {
      font-size: 25px;
    }
  }
}
