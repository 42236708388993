.services {
  .wide-img {
    .container {
      @media (max-width: 1000px) {
        max-width: 100%;
      }
    }

    padding: 70px 0;

    &__background {
      position: relative;
      aspect-ratio: 2/1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 80px;
      background-color: $black;
      border-radius: 20px;

      @media (max-width: 1000px) {
        aspect-ratio: 390/320;
        border-radius: 0;
      }
    }

    &__photo {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.7;
      object-fit: cover;
      border-radius: 20px;

      @media (max-width: 1000px) {
        border-radius: 0;
      }
    }

    &__heading {
      position: relative;
      font-size: 24px;
      font-weight: $extra-bold;
      color: $white;
      z-index: 1;
      max-width: 600px;
      text-align: center;
      margin-top: 40px;

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    &__button {
      position: relative;
      z-index: 1;
      cursor: pointer;
      background-color: $main;
      color: $white;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: $bold;
      padding: 15px 20px;
      text-align: center;
      transform: rotate(-10deg) scale(1);
      transition: all 0.3s;
      text-transform: uppercase;

      @media (max-width: 600px) {
        font-size: 16px;
      }

      &:hover {
        background-color: $black;
        transform: scale(1.2);
      }
    }

    &__arrow {
      width: 20px;
      transform: rotate(-90deg);
      margin-left: 20px;
    }
  }
}
