.career {
  .benefits {
    background-color: $white;
    color: $black;
    padding: 100px 0;
    position: relative;
    z-index: 1;

    @media (max-width: 800px) {
      padding: 70px 0;
    }

    &__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
      gap: 60px 40px;

      @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 40px;
      }
    }

    &__heading {
      font-weight: $extra-bold;
      font-family: $text;
      margin: 15px 0;
      font-size: 21px;
      line-height: 1.2;
    }

    &__icon {
      height: 40px;
      width: auto;
    }

    &__text {
      font-size: 16px;
    }
  }
}
