.homepage{
  .numbers{
    padding: 100px 0;
    color: $white;
    background-color: $light-black;
    position: relative;
    overflow: hidden;

    @media (max-width: 900px) {
      padding: 50px 0;
    }

    &__container{
      display: grid;
      max-width: 80%;
      margin: 0 auto;
      grid-template-columns: 20fr 20fr 30fr 30fr 30fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "heading      heading      projects  dot       clients"
        "technologies technologies .         employees . "
        "experience   experience   photo     photo     photo"
        "arrow        arrow        photo     photo     photo";

      @media (max-width: 1100px) {
        overflow-wrap: anywhere;
        max-width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr 1fr 1fr 1fr 2fr 1fr ;
        grid-gap: 10px;
        grid-template-areas:
          "heading      heading"
          "projects     dot"
          "clients      employees"
          "technologies technologies"
          "experience   experience"
          "photo        photo"
          "arrow        arrow";
      }

      @media (max-width: 500px) {
        grid-template-rows: auto 1fr 1fr 1fr 1fr 2fr 0.5fr ;
      }
    }

    &__wrapper {
      max-width: 100%;
      overflow: hidden;
      position: absolute;
      top: 40%;
      transform: rotate(-5deg);

      @media (max-width: 900px) {
        top: 38%;
      }

      @media (max-width: 400px) {
        top: 41%;
      }
    }

    &__text {
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      animation: numbers 100s linear infinite;
      width: 9180px;
      background-image: url('/assets/images/theme/shapes/backgorund-baner.svg');
      background-repeat: repeat-x;
      height: 100px;
      background-size: 30%;

      p {
        display: inline-block;
        font-size: 45px;
        color: $white;
        font-weight: $bold;

        @media (max-width: 600px) {
          font-size: 30px;
        }
      }

      span {
        color: $main;
        position: relative;
        top: -10px;
        font-size: 15px;
        margin: 0 20px;

        @media (max-width: 600px) {
          top: -5px;
          font-size: 10px;
          margin: 0 10px;
        }
      }
    }

    @keyframes numbers {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-50%);
      }
    }

    &__additional {
      display: flex;

      span {
        font-size: 35px;
        font-weight: $bold;
        margin: 0 5px;

        @media (max-width: 900px) {
          font-size: 30px;
        }

        @media (max-width: 450px) {
          font-size: 25px;
        }
      }
    }

    &__grid {
      display: inline-grid;
      padding: 30px;

      @media (max-width: 900px) {
        padding: 15px;
      }

      h4 {
        font-size: 35px;
        line-height: 1;

        @media (max-width: 900px) {
          font-size: 30px;
        }

        @media (max-width: 450px) {
          font-size: 25px;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: $regular;
        align-self: flex-end;

        @media (max-width: 500px) {
          font-size: 15px;
        }
      }
    }

    &__heading {
      grid-area: heading;
      font-size: 24px;
      padding: 10px;

      @media (max-width: 1000px) {
        font-size: 22px;
      }
    }

    &__projects {
      grid-area: projects;
      background-color: $main;
      border-radius: 30px;
      color: $black;
    }

    &__dot {
      grid-area: dot;
      align-self: center;
      text-align: center;
      color: $main;
    }

    &__clients {
      grid-area: clients;
      background-color: $light-gray;
      border-radius: 30px;
    }

    &__technologies {
      grid-area: technologies;
      position: relative;
      overflow: hidden;

      h4 {
        color: $light-gray;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 2px;
      }
    }

    &__employees {
      grid-area: employees;
      background-color: $coffee;
      border-radius: 30px;
      color: $black;
      z-index: 1;
    }

    &__experience {
      grid-area: experience;
      background-color: $dark-gray;
      border-radius: 30px;
    }

    &__arrow {
      grid-area: arrow;
      align-self: center;
      text-align: center;

      img {
        width: 50px;
        height: auto;
        transition: 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    &__photo {
      grid-area: photo;

      img {
        border-radius: 30px;
        max-height: 550px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
