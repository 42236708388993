.services {
  .steps {
    counter-reset: list-one;

    .container {
      display: flex;
      flex-direction: column;
      row-gap: 60px;
    }

    &__head-row {
      display: flex;
      align-items: center;
      column-gap: 50px;

      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__title-container {
      flex: 4;
    }

    &__sub-heading {
      @include section-sub-heading;
    }

    &__heading {
      @include section-heading;

      color: $black;
    }

    &__paragraph {
      flex: 6;
      color: $light-gray;
      font-size: 22px;

      @media (max-width:1300px) {
        font-size: 18px;
      }
    }

    &__container {
      display: flex;
      justify-content: space-between;

      @media (max-width:1000px) {
        flex-wrap: wrap;
        row-gap: 100px;
      }
    }

    &__element {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 30px;
      width: 25%;

      @media (max-width:1000px) {
        width: 50%;
      }

      @media (max-width:600px) {
        width: 100%;
      }

      &:not(:first-of-type)::before {
        @include arrow-list;

        top: 30%;
        left: -6%;

        @media (max-width:1000px) {
          left: -8%;
        }

        @media (max-width:600px) {
          left: 50%;
          top: -30%;
          transform: translateX(-50%) rotate(90deg);
        }
      }

      &:nth-of-type(3)::before {
        @media (max-width: 1000px) {
          @include common-arrow-position-mobile;
        }

        @media (max-width:600px) {
          left: 50%;
          top: -30%;
          transform: translateX(-50%) rotate(90deg);
        }
      }

      &-container {
        position: relative;
        background-color: $main;
        width: 100px;
        height: 100px;
        border-radius: 666px;
        display: grid;
        place-items: center;

        &::before {
          counter-increment: list-one;
          content: counter(list-one);
          color: $white;
          display: grid;
          place-items: center;
          position: absolute;
          background-color: $black;
          width: 35px;
          height: 35px;
          border-radius: 666px;
          top: 0;
          left: 0;
          font-size: 16px;
        }
      }

      &-img {
        object-fit: contain;
        width: 40%;
      }

      &-description {
        text-align: center;
        font-size: 22px;
        font-weight: $extra-bold;
        max-width: 80%;

        @media (max-width:1300px) {
          font-size: 20px;
        }

        @media (max-width:1000px) {
          font-size: 16px;
          max-width: 100%;
        }
      }
    }
  }
}

[data-aos="right-before-one"] {
  &::before {
    opacity: 0;
    transform: translateX(-100px);
    transition-duration: 0.4s;
    transition-property: opacity, transform;

    @media (max-width: 600px) {
      transform: translateY(-100px) translateX(-50%) rotate(90deg) !important;
    }
  }

  &.aos-animate::before {
    opacity: 1;
    transform: none;

    @media (max-width: 600px) {
      transform: translateY(0) translateX(-50%) rotate(90deg) !important;
    }
  }
}

[data-aos="mix-before-one"] {
  &::before,
  &::after {
    opacity: 0;
    transform: translateX(-100px);
    transition-delay: 500ms;
    transition-duration: 0.4s;
    transition-property: opacity, transform;

    @media (max-width: 1000px) {
      transform: translateX(100px) translateY(-100px) rotate(140deg) !important;
      transition-delay: 0ms;
    }

    @media (max-width: 600px) {
      transform: translateY(-100px) translateX(-50%) rotate(90deg) !important;
    }
  }

  &.aos-animate::before,
  &.aos-animate::after {
    opacity: 1;
    transform: none;

    @media (max-width: 1000px) {
      transform: translateY(0) translateX(0) rotate(140deg) !important;
    }

    @media (max-width: 600px) {
      transform: translateY(0) translateX(-50%) rotate(90deg) !important;
    }
  }
}

[data-aos="right-before-two"] {
  &::before {
    opacity: 0;
    transform: translateX(-100px);
    transition-delay: 1000ms;
    transition-duration: 0.4s;
    transition-property: opacity, transform;

    @media (max-width: 1000px) {
      transition-delay: 0ms;
    }

    @media (max-width: 600px) {
      transform: translateY(-100px) translateX(-50%) rotate(90deg) !important;
    }
  }

  &.aos-animate::before {
    opacity: 1;
    transform: none;

    @media (max-width: 600px) {
      transform: translateY(0) translateX(-50%) rotate(90deg) !important;
    }
  }
}

[data-aos="mix-before-two"] {
  &::before,
  &::after {
    opacity: 0;
    transform: translateX(-100px);
    transition-delay: 1000ms;
    transition-duration: 0.4s;
    transition-property: opacity, transform;

    @media (max-width: 1000px) {
      transform: translateX(100px) translateY(-100px) rotate(140deg) !important;
      transition-delay: 0ms;
    }

    @media (max-width: 600px) {
      transform: translateY(-100px) translateX(-50%) rotate(90deg) !important;
    }
  }

  &.aos-animate::before,
  &.aos-animate::after {
    opacity: 1;
    transform: none;

    @media (max-width: 1000px) {
      transform: translateY(0) translateX(0) rotate(140deg) !important;
    }

    @media (max-width: 600px) {
      transform: translateY(0) translateX(-50%) rotate(90deg) !important;
    }
  }
}

[data-aos="right-before-three"] {
  &::before {
    opacity: 0;
    transform: translateX(-100px);
    transition-delay: 1500ms;
    transition-duration: 0.4s;
    transition-property: opacity, transform;

    @media (max-width: 1000px) {
      transition-delay: 0ms;
    }

    @media (max-width: 600px) {
      transition-delay: 0ms;
      transform: translateY(-100px) translateX(-50%) rotate(90deg) !important;
    }
  }

  &.aos-animate::before {
    opacity: 1;
    transform: none;

    @media (max-width: 600px) {
      transform: translateY(0) translateX(-50%) rotate(90deg) !important;
    }
  }
}
