.career {
  .photos {
    padding: 50px 0;

    &__container {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-areas:
        "four  four  four  dot   two   two   two   two"
        "three three three three claim claim claim claim"
        "three three three three five  five  five  five";
      gap: 15px;

      @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-areas:
        "two"
        "four"
        "claim"
        "three"
        "five"
      }

      img {
        display: block;
        border-radius: 20px;
        object-fit: cover;
        object-position: top;
        aspect-ratio: 2/1;
      }
    }

    &__dot {
      grid-area: dot;
      align-self: center;
      text-align: center;
      color: $main;

      @media (max-width: 800px) {
        display: none;
      }
    }

    &__claim {
      grid-area: claim;
      display: flex;
      align-self: center;
      background-color: $main;
      border-radius: 20px;

      @media (max-width: 800px){
        justify-content: center;
      }
    }

    &__claim-text {
      font-size: 18px;
      font-weight: $extra-bold;
      padding: 25px 50px;
      color: $white;
      text-align: center;
      margin: 50px auto;
    }

    &__two {
      grid-area: two;
      position: relative;
    }

    &__three {
      grid-area: three;
      position: relative;

      img {
        aspect-ratio: 5/3;
      }
    }

    &__four {
      grid-area: four;

      img {
        aspect-ratio: 3/2;
      }
    }

    &__five {
      grid-area: five;
    }
  }
}
