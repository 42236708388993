.services {
  .expansion {
    padding: 130px 0;
    background-color: $black;

    @media (max-width: 1200px) {
      padding: 100px 0;
    }

    @media (max-width: 1000px) {
      padding: 50px 0;
    }

    .container {
      display: flex;
      column-gap: 60px;

      @media (max-width:1000px) {
        flex-direction: column;
      }
    }

    &__squares {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 10px;
      grid-template-areas:
        "img-wide img-wide"
        "shape img"
      ;

      @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
          "img-wide img-wide"
          "shape    img"
        ;
      }

      &-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 20px;
        padding: 10px;
      }

      &-orange {
        grid-area: orange;
        color: $white;
        background-color: $main;
      }

      &-gray {
        grid-area: gray;
        color: $white;
        background-color: $light-gray;
      }

      &-dark {
        grid-area: dark;
        color: $white;
        background-color: $dark-gray;
      }

      &-coffee {
        grid-area: coffee;
        background-color: $coffee;
      }

      &-shape {
        display: grid;
        place-items: center;
        grid-area: shape;

        &-img {
          width: 75px;

          @media (max-width:1000px) {
            width: 35px;
          }
        }
      }

      &-img-wide {
        grid-area: img-wide;
      }

      &-img {
        grid-area: img;
      }
    }

    &__photo {
      object-fit: cover;
      border-radius: 20px;
      display: block;
    }

    &__number {
      font-weight: $bold;
      font-size: 30px;

      &-description {
        font-size: 18px;
        text-align: center;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }

    &__sub-heading {
      color: $main;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 18px;
    }

    &__heading {
      line-height: 1;
      font-size: 50px;
      font-weight: $bold;
      color: $white;
      padding-bottom: 20px;

      @media (max-width:1300px) {
        font-size: 48px;
      }

      @media (max-width: 1000px) {
        font-size: 30px;
      }
    }

    &__description {
      color: $white;
      font-size: 22px;
      margin: 20px 0;

      @media (max-width:1300px) {
        font-size: 18px;
      }
    }
  }
}
