.about {
  .company-desktop {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 140px 0;
    background-color: $black;
    background-image: url("/assets/images/theme/shapes/background-black-line-opacity.svg");

    @media (max-width: 700px) {
      padding: 70px 0;
    }

    &__container {
      max-width: 1920px;
      padding: 0 30px;
      margin: 0 auto;
    }

    &__grid {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 100px auto 1fr;
      grid-template-areas:
        "heading heading additional-text"
        "main-text main-text one"
        "two three bottom-text";

        @media (max-width: 1000px) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr auto 1fr 1fr;
          grid-template-areas:
          "heading one"
          "main-text main-text"
          "additional-text two"
          "three bottom-text"
        }

        @media (max-width: 700px) {
          grid-template-columns: 1fr;
          grid-template-rows: 200px repeat(3, auto) 200px repeat(2, auto);
          grid-template-areas:
          "heading"
          "one"
          "main-text"
          "two"
          "additional-text"
          "three"
          "bottom-text"
        }
    }

    &__heading {
      display: flex;
      column-gap: 20px;
      background-color: $main;
      color: $white;
      font-size: 26px;
      font-weight: $extra-bold;
      padding: 10px 20px;
      text-transform: uppercase;
      transform: matrix(0.98, -0.19, 0.19, 0.98, 0, 0);
      width: fit-content;
      max-width: 500px;
      position: absolute;

      @media (max-width: 1000px) {
      font-size: 18px;
      transform: matrix(0.98, -0.19, 0.19, 0.98, 0, 100);
      width: min-content;
      }

      @media (max-width: 700px) {
        transform: matrix(0.98, -0.19, 0.19, 0.98, 0, 0);
        font-size: 28px;
      }

      @media (max-width: 500px) {
        font-size: 19px;
      }

      @media (max-width: 400px) {
        font-size: 15px;
      }

      &::before {
        content: url('/assets/images/theme/shapes/arrow-white.svg');
        display: block;
        align-self: center;
        margin-left: 20px;
      }
    }

    &__main-text {
      display: grid;
      place-items: center;
      font-size: 32px;
      font-weight: $bold;
      color: $white;
      position: relative;
      margin-left: 50px;
      line-height: 2;
      grid-area: main-text;
      min-height: 300px;

      @media (max-width: 1400px) {
        font-size: 24px;
      }

      @media (max-width: 1000px) {
        font-size: 24px;
        margin: 0 50px;
      }

      @media (max-width: 600px) {
        font-size: 20px;
        margin: 0 30px;
      }
    }

    &__additional-text-container {
      grid-area:additional-text;
      position: relative;

      @media (max-width: 1000px) {
        display: grid;
        place-items: center;
      }
    }

    &__additional-text {
      margin-left: auto;
      background-color: $main;
      color: $white;
      font-size: 30px;
      font-weight: $extra-bold;
      padding: 10px 20px;
      text-transform: uppercase;
      transform: matrix(0.98, 0.19, -0.19, 0.98, 0, 0);
      width: fit-content;

      @media (max-width: 1000px) {
        margin: 0;
      }

      @media (max-width: 700px) {
        top:0;
        right: 10%;
        font-size: 24px;
      }
    }

    &__bottom-text-container {
      grid-area: bottom-text;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .button-main {
        @media (max-width: 700px){
          font-size: 16px;
        }
      }

      @media (max-width: 900px){
        display: block;
      }

      @media (max-width: 700px){
        display: block;
        margin: 30px auto;
      }
    }

    &__bottom-text {
      max-width: 400px;
      font-size: 30px;
      font-weight: $bold;
      color: $white;

      @media (max-width: 1400px) {
        font-size: 22px;
      }

      &--color {
        color: $main;
        text-transform: uppercase;
      }
    }

    &__photo-container{
      position: relative;
      aspect-ratio: 4/3;
      transition: all 0.4s;
      transform: rotateX(0);

      &.active {
        transform: rotateX(180deg);
      }
    }

    &__photo {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      border-radius: 20px;
      transform-style: preserve-3d;
      transition: all 0.4s;

      &.active {
        transform: rotateX(180deg);
      }
    }

    &__photo-container-one {
      grid-area: one;
    }

    &__photo-container-two {
      grid-area: two;
    }

    &__photo-container-three {
      grid-area: three;
    }
  }
}
