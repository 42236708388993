.career {
  &__bottom {
    display: grid;
  }

  @media (max-width: 900px) {
    .process {
      order: 3;
    }

    .photos {
      order: 2;
    }

    .opinion {
      order: 1;
    }
  }
}
