.about {
  .offices {
    padding: 50px 0 0;
    background-color: $coffee;

    &__sub-heading {
      @include section-sub-heading;
    }

    &__heading {
      @include section-heading;

      color: $black;
    }

    &__tab {
      max-width: 100%;
      color: white;
      overflow: hidden;

      &-label {
        font-size: 45px;
        font-weight: $extra-bold;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        @media (max-width: 1000px) {
          font-size: 37px;
        }

        &::after {
          content: url('/assets/images/theme/shapes/arrow-white.svg');
          width: 32px;
          transition: all .5s;
          transform: scale(1.3);

          @media (max-width: 1000px) {
            transform: scale(1);
            font-size: 20px;
          }
        }
      }

      &-content {
        max-height: 0;
        transition: all .5s;
      }

      &-wrapper {
        display: grid;
        grid-template-columns: 21% 79%;
        column-gap: 3px;
        background-color: $white;
        border-top: solid $white 3px;
        border-bottom: solid $white 3px;

        @media (max-width: 1000px) {
          grid-template-columns: 1fr;
        }
      }

      &-address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 70px;
        padding-right: 3px;

        @media (max-width: 1000px) {
          flex-wrap: wrap;
          padding: 0 0 30px;
          align-items: baseline;
          row-gap: 30px;
          column-gap: 10px;
        }

        &--krakow {
          background-color: $main;
        }

        &--olsztyn {
          background-color: $dark-main;
        }

        &--zabrze {
          background-color: $darker-main;
        }

        &--hamburg {
          background-color: $black;
        }

        & address {
          @media (max-width: 1000px) {
            width: 100%;
          }
        }

        & p {
          font-weight: $bold;
          font-style: normal;
          font-size: 30px;

          @media (max-width: 1000px) {
            font-size: 16px;
          }
        }

        & img {
          width: 23px;
        }
      }

      &-place {
        padding: 70px 70px 70px 0;
        display: flex;
        flex-direction: column;
        row-gap: 70px;

        @media (max-width: 1000px) {
          padding: 30px 0;
        }

        &--krakow {
          background-color: $main;
        }

        &--olsztyn {
          background-color: $dark-main;
        }

        &--zabrze {
          background-color: $darker-main;
        }

        &--hamburg {
          background-color: $black;
        }
      }

      &-city {
        color: $white;
        font-size: 26px;
        font-weight: $extra-bold;

        @media (max-width: 700px) {
          font-size: 22px;
        }
      }

      &-coordinates {
        font-size: 16px;
      }

      &-map {
        display: flex;
        color: $white;
        font-size: 14px;
        align-items: center;
        font-weight: $bold;

        &--desktop {
          @media (max-width: 1000px) {
            display: none;
          }
        }

        &--mobile {
          display: none;

          @media (max-width: 1000px) {
            display: flex;
          }
        }

        img {
          width: 20px;
          height: auto;
          margin-right: 15px;
        }
      }

      input {
        position: absolute;
        opacity: 0;
        z-index: -1;
      }

      input:checked {
        + .offices__tab-label {
          &::after {
            transform: rotate(180deg);
          }
        }

        ~ .offices__tab-content {
          max-height: 100vh;
          padding: 1em 0;
          margin-bottom: 40px;

          @media (max-width:1000px) {
            margin-bottom: 20px;
          }
        }
      }
    }

    &__krakow {
      background-color: $main;
    }

    &__olsztyn {
      background-color: $dark-main;
    }

    &__zabrze {
      background-color: $darker-main;
    }

    &__hamburg {
      background-color: $black;
    }
  }
}
