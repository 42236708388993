.portfolio  {
  .goals {
    padding: 40px 0;
    z-index: 1;
    position: relative;

    &__heading {
      @include section-heading;

      color: $black;
      margin-bottom: 30px;
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }
    }

    &__list-single {
      grid-template-columns: 1fr;
    }

    &__item {
      background-color: $coffee;
      padding: 50px;
      border-radius: 20px;
      display: flex;
      gap: 20px;

      @media (max-width: 1300px) {
        padding: 30px;
      }

      @media (max-width: 900px) {
        padding: 40px;
      }

      @media (max-width: 600px) {
        display: block;
      }
    }

    &__item-heading {
      font-size: 20px;
      font-weight: $extra-bold;
      font-family: $text;

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    &__item-text {
      font-size: 18px;
      margin-top: 20px;
      color: $light-gray;
    }

    &__arrow {
      width: 30px;
      height: 23px;
    }
  }
}
