.about {
  .numbers {
    padding: 100px 0;
    position: relative;
    background-image: url("/assets/images/theme/shapes/background-white-line.svg");

    @media (max-width: 900px) {
      padding: 50px 0;
    }

    &__item {
      display: grid;
      font-family: $text;
      grid-template-columns: 30fr 70fr;
      gap: 50px;
      align-items: center;
      padding-bottom: 80px;
      margin: 0 auto;

      @media (max-width: 1800px) {
        padding-bottom: 60px;
      }

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        gap: 20px;
        text-align: center;
        padding-bottom: 40px;
      }
    }

    &__number {
      text-align: right;
      font-weight: $bold;
      color: $main;
      font-size: 110px;
      line-height: 0;

      @media (max-width: 900px) {
        line-height: 0.8;
        text-align: center;
        font-size: 70px;
      }
    }

    &__text {
      font-weight: $extra-bold;
      font-family: $text;
      font-size: 35px;
      line-height: 1.2;

      @media (max-width: 1300px) {
        font-size: 30px;
      }

      @media (max-width: 900px) {
        font-size: 18px;
      }
    }
  }
}
