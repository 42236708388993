.portfolio  {
  .challenges {
    padding: 80px 0;

    &__container {
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;

      @media (max-width: 900px) {
        grid-template-columns: 100%;
      }
    }

    &__card {
      background-color: $main;
      padding: 40px;
      border-radius: 20px;
      color: $white;
      margin-right: 40px;

      @media (max-width: 900px) {
        margin-right: 0;
        padding: 30px;
      }
    }

    &__icon {
      aspect-ratio: auto;
      width: 120px;

      @media (max-width: 900px) {
        width: 60px;
      }
    }

    &__title {
      font-size: 30px;
      padding: 20px 0;

      @media (max-width: 900px) {
        font-size: 22px;
      }
    }

    &__text {
      font-size: 20px;
    }

    &__slide {
      padding: 30px 0;
      text-align: center;
      font-size: 20px;

      @media (max-width: 900px) {
        display: grid;
        align-items: center;
      }
    }
  }
}
