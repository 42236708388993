.modal {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 200ms ease-in-out;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 40%;
  overflow-y: auto;
  display: grid;
  place-items: center;

  @media (max-width: 1000px) {
    max-width: 60%;
  }

  @media (max-width: 600px) {
    max-width: 80%;
  }

  &.active {
    transform: translate(-50%, -50%) scale(1);
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    justify-content: center;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
    transform: translate(50%, -50%);
    border: none;
    z-index: 1;
    background-color: transparent;
    font-size: 50px;
    color: $main;
  }

  &__overlay {
    position: fixed;
    opacity: 0;
    transition: 200ms ease-in-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(18, 18, 18, 50%);
    pointer-events: none;
  }

  &__overlay.active {
    opacity: 1;
    pointer-events: all;
    z-index: 999;
  }
}
