.homepage {
  .work {
    position: relative;
    overflow: hidden;
    background: $light-black;

    &__background {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      z-index: 0;
      opacity: 0.6;

      @media (max-width: 2000px) {
        object-fit: cover;
      }

      @media (max-width: 800px) {
        left: 0%;
        width: 100%;
      }
    }

    &__container {
      min-height: 600px;
      position: relative;
      z-index: 1;

      @media (max-width: 800px) {
        min-height: 500px;
      }
    }

    &__text {
      white-space: nowrap;
      overflow: hidden;
      display: inline-flex;
      animation: work 80s linear infinite;
      width: calc(315px * 16);

      @media (max-width: 900px) {
        width: calc(208px * 16);
      animation: work-mobile 80s linear infinite;
      }


      &-p {
        display: inline-block;
        font-size: 40px;
        color: $white;
        text-transform: uppercase;
        font-weight: $extra-bold;
        margin: 0 10px;
        width: 340px;

        @media (max-width: 900px) {
          font-size: 20px;
          width: 190px;
        }

        &::after {
          content: '\2B24';
          margin-left: 18px;
          font-size: 10px;
          position: relative;
          top: -8px;

          @media (max-width: 900px) {
            top: -4px;
          }
        }
      }
    }

    @keyframes work {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(calc(-315px * 8));
      }
    }
    @keyframes work-mobile {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(calc(-208px * 8));
      }
    }

    &__wrapper-two {
      max-width: 110%;
      background-color: $black;
      overflow: hidden;
      position: absolute;
      bottom: 130px;
      left: -20px;
      transform: rotate(3deg);
      padding: 10px 0;

      @media (max-width: 900px) {
        transform: rotate(6deg);
      }

      .work__text-p {
        color: $main;
      }
    }

    &__button {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;

      .button-main {
        @media (max-width: 800px) {
          font-size: 18px;
          padding: 10px 20px;
        }
      }
    }
  }
}
