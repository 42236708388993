.services {
  .benefits {
    padding: 60px 0;

    @media (max-width: 1000px) {
      padding: 50px 0;
    }

    &__sub-heading {
      @include section-sub-heading;
    }

    &__heading {
      @include section-heading;

      color: $black;
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 80px;
      row-gap: 40px;
      list-style-type: none;
      margin: 30px 0;

      @media (max-width:1000px) {
        grid-template-columns: 1fr;
        gap: 40px;
      }
    }

    &__element {
      display: flex;
      column-gap: 20px;
      color: $light-gray;
      font-size: 22px;
      position: relative;

      @media (max-width:1300px) {
        font-size: 18px;
      }

      &-arrow {
        width: 40px;
        height: 29px;
      }

      // &::before {
      //   content: '';
      //   position: absolute;
      //   z-index: 2;
      //   top: 0;
      //   left: -60px;
      //   width: 40px;
      //   height: 29px;
      //   background-image: url('/assets/images/theme/shapes/arrow-red.svg');
      //   background-repeat: no-repeat;
      // }
    }
  }
}
