.blog-loop {
  padding: 50px 0;
  position: relative;
  background-image: url("/assets/images/theme/shapes/background-white-line.svg");

  @media (max-width: 900px) {
    padding: 25px 0;
  }

  &__container {
    display: grid;
    gap: 50px;
    overflow: hidden;

    @media (max-width: 900px) {
      gap: 30px;
    }
  }

  &__article {
    background-color: $coffee;
    padding: 60px;
    border-radius: 20px;
    width: 50%;

    &:nth-child(even){
      justify-self: end;
    }

    @media (max-width: 1800px) {
      padding: 40px;
    }

    @media (max-width: 1000px) {
      width: auto;
    }

    @media (max-width: 800px) {
      padding: 20px;
    }
  }

  &__tag {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: $bold;
    white-space: nowrap;
    position: relative;
    color: $main;
    letter-spacing: 1px;
    transition: 0.3s;

    @media (max-width: 800px) {
      font-size: 14px;
      letter-spacing: 1px;
    }

    &:not(:last-child)::after {
      content: '\2219';
      color: $main;
      margin-left: 10px;
      font-weight: bold;
    }

    &-wrapper {
      line-height: 1;
    }

    &:hover {
      color: $black;
    }
  }

  &__title {
    font-size: 32px;
    margin: 20px 0 30px;

    @media (max-width: 800px) {
      font-size: 20px;
    }

    @media (max-width: 450px) {
      margin-bottom: 20px;
    }

    a {
      display: block;

      &:hover {
        color: $main;
      }
    }
  }

  &__button {
    color: $black;
    border-color: $black;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    padding: 10px 25px;
    gap: 20px;
    border-width: 2px;

    img {
      width: 10px;
      transform: rotate(-90deg);
    }

    &:hover {
      background-color: $main;
      border-color: $main;
      color: $white;

      img {
        filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(113deg) brightness(103%) contrast(101%);
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;

    @media (max-width: 450px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 20px;
    }
  }

  &__date {
    flex: 1;
    text-align: right;
    color: $light-gray;
    font-size: 14px;
    font-weight: $bold;
    letter-spacing: 2px;
  }
}
