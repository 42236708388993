.homepage {
  .blog {
    padding: 100px 0 150px;
    overflow: hidden;

    @media (max-width: 900px) {
      padding: 50px 0;
    }

    &__container {
      display: grid;
      grid-template-columns: 30fr 35fr 35fr 5fr;
      gap: 20px;
      position: relative;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }
    }

    &__blog {
      display: grid;
      position: relative;

      @media (max-width: 900px) {
        justify-self: center;
      }

      #blog__show {
        position: absolute;
        background-color: $main;
        display: grid;
        border-radius: 666px;
        height: 400px;
        width: 400px;
        align-self: center;
        color: $white;

        @media (max-width: 900px) {
          position: relative;
          width: 150px;
          height: 150px;
        }

        img {
          align-self: center;
          width: 50px;
          margin-left: 50px;
          height: auto;
          transform: rotate(-90deg);

          @media (max-width: 1300px) {
            margin-left: 20px;
            width: 35px;
          }

          @media (max-width: 900px) {
            width: 30px;
            margin-left: 0;
            justify-self: center;
          }
        }
      }
    }

    &__wrapper {
      display: inline-grid;
    }

    &__heading {
      @include section-heading;

      color: $black;
    }

    &__sub-heading {
      font-size: 24px;
      color: $light-gray;
    }

    &__post {
      padding: 30px;
      display: grid;
      min-height: 350px;
      position: relative;

      &-wrapper {
        display: grid;
      }
    }

    &__info {
      align-self: flex-end;
    }

    &__tag {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: $regular;
      white-space: nowrap;
      z-index: 5;
      position: relative;
      color: $white;

      &:not(:last-child)::after {
        content: '\2219';
        color: $main;
        margin-left: 5px;
        font-weight: bold;
      }
    }

    &__date {
      font-size: 14px;
      font-weight: $bold;
      color: $light-gray;
      letter-spacing: 3px;
      z-index: 5;
      position: relative;
    }

    &__title {
      font-size: 22px;
      color: $white;
      z-index: 5;
      position: relative;
    }

    &__thumbnail {
      position: absolute;
      top: 0;
      left: 0%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 0;
      background-color: $black;
      border-radius: 30px;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        border-radius: 30px;
      }
    }
  }
}
