.button-main {
  cursor: pointer;
  background-color: $main;
  color: $white;
  display: inline-flex;
  align-items: center;
  font-size: 22px;
  font-weight: $bold;
  border: 0;
  padding: 15px 30px;
  text-align: center;
  border-radius: 666px;
  margin: 10px 0;
  text-decoration: none;
  transition: all 0.3s;
  text-transform: uppercase;
  font-family: $heading;
  @include user-select-none;

  &:hover {
    background-color: $black;
  }

  &-img {
    display: inline;
    margin-left: 30px;
    width: auto;
    height: 25px;
    transform: rotate(-90deg);
  }
}

.button-empty {
  cursor: pointer;
  font-family: $heading;
  background-color: transparent;
  color: white;
  display: inline-flex;
  align-items: center;
  font-size: 22px;
  font-weight: $bold;
  border-radius: 666px;
  border: 4px solid $white;
  padding: 15px 30px;
  text-transform: uppercase;
  transition: all 0.3s;
  @include user-select-none;

  &:hover {
    background-color: $main;
    border-color: $main;
  }

  &-img {
    display: inline;
    margin-left: 30px;
    width: auto;
    height: 25px;
    transform: rotate(-90deg);
  }
}

.button-accordion {
  cursor: pointer;
  background-color: transparent;
  color: $white;
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  font-weight: $bold;
  padding: 15px 30px;
  border-radius: 666px;
  margin: 10px 0;
  text-decoration: none;
  transition: all 0.3s;
  text-transform: uppercase;
  border: solid $white 3px;
  @include user-select-none;

  @media (max-width: 1000px) {
    font-size: 14px;
    padding: 10px 20px;
  }

  &:hover {
    background-color: $black;
    border-color: $black;
  }

  &-img {
    display: inline;
    margin-left: 30px;
    width: auto;
    transform: rotate(-90deg);
  }
}
