.portfolio  {
  .navigation {
    padding: 30px 0;

    @media (max-width: 900px) {
      padding: 20px;
    }

    &__hr {
      border: none;
      height: 3px;
      color: $black;
      background-color: $black;
    }

    &__links {
      display: flex;
      justify-content: space-between;
      padding: 20px;

      @media (max-width: 900px) {
        padding: 10px;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      gap: 30px;
      font-weight: $bold;
      font-size: 40px;

      &:hover {
        color: $main;

        img {
          filter: invert(37%) sepia(73%) saturate(1201%) hue-rotate(335deg) brightness(97%) contrast(89%);
        }
      }

      @media (max-width: 900px) {
        font-size: 25px;
        gap: 20px;
      }

      @media (max-width: 600px) {
        font-size: 18px;
        gap: 10px;
      }

      img {
        aspect-ratio: auto;
        width: 40px;

        @media (max-width: 900px) {
          width: 20px;
        }

        @media (max-width: 600px) {
          width: 10px;
        }
      }
    }

    &__previous {
      img {
        transform: rotate(90deg);
      }
    }

    &__next {
      img {
        transform: rotate(-90deg);
      }
    }
  }
}
