.services {
  .project-workshops {
    padding: 70px 0 30px;
    margin-bottom: -60px;

    @media (max-width: 1400px) {
      margin-bottom: -40px;
    }

    @media (max-width: 1000px) {
      margin-bottom: 0;
    }

    &__title {
      font-size: 50px;
      font-weight: $bold;
      text-transform: uppercase;
      z-index: 0;

      @media (max-width: 1300px) {
        font-size: 35px;
      }

      @media (max-width: 900px) {
        font-size: 28px;
      }

      @media (max-width: 500px) {
        font-size: 22px;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        background-image: url('/assets/images/theme/shapes/square-orange.svg');
        background-repeat: no-repeat;
        top: 0;
        left: -70px;
        width: 198px;
        height: 198px;

        @media (max-width: 1400px) {
          transform: scale(0.8);
        }

        @media (max-width: 1200px) {
          top: -45px;
          left: -70px;
          transform: scale(0.5);
        }

        @media (max-width: 1000px) {
          top: -45px;
          left: -80px;
        }

        @media (max-width: 600px) {
          transform: scale(0.4);
          top: -65px;
          left: -90px;
        }

        @media (max-width: 600px) {
          transform: scale(0.4);
          top: -65px;
          left: -95px;
        }
      }
    }

    &__wrapper {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: 35% 30% 35%;
      gap: 20px;
      grid-template-areas:
        "heading heading img-one"
        "txt     img-two img-three"
        "txt     shape   img-three";

      @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto) 20% 20% 1fr;
        gap: 10px;
        grid-template-areas:
          "heading heading"
          "txt     txt"
          ".       img-one"
          "img-two img-three"
          "shape   img-three";
      }
    }

    &__heading {
      display: flex;
      align-items: center;
      grid-area: heading;
      position: relative;
    }

    &__img-one {
      grid-area: img-one;
    }

    &__img-two {
      grid-area: img-two;
    }

    &__img-three {
      grid-area: img-three;
    }

    &__text {
      grid-area: txt;
      font-size: 24px;
      color: $black;

      @media (max-width: 1200px) {
        font-size: 20px;
      }

      @media (max-width: 500px) {
        font-size: 18px;
      }
    }

    &__paragraph {
      font-size: 24px;

      @media (max-width: 1300px) {
        font-size: 20px;
      }

      @media (max-width: 500px) {
        font-size: 18px;
      }
    }

    &__shape {
      grid-area: shape;
      display: grid;
      place-items: center;

    }

    &__shape-img {
      width: 65px;

      @media (max-width: 600px) {
        width: 35px;
      }
    }

    &__photo {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
}
