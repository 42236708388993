.blog-hero {
  overflow: hidden;
  padding: 180px 0 70px;
  position: relative;
  background-image: url("/assets/images/theme/shapes/background-white-line.svg");

  @media (max-width: 800px) {
    padding: 130px 0 50px;
  }

  &__container {
    z-index: 1;
    position: relative;
  }

  &__scroll-wrapper {
    display: grid;
    grid-template-columns: 45fr 40fr;
    align-items: flex-end;
    margin-top: 40px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      margin-top: -50px;
    }
  }

  &__heading {
    font-size: 100px;
    line-height: 1.2;
    max-width: 80%;
    color: $black;
    font-weight: $extra-bold;
    position: relative;

    @media (max-width: 1800px) {
      font-size: 70px;
    }

    @media (max-width: 1300px) {
      font-size: 5vw;
    }

    span {
      color: $main;
    }
  }

  &__scroll {
    display: grid;
    justify-content: end;
    text-align: right;
    transform: rotate(-7deg);
    font-family: $heading;

    @media (max-width: 800px) {
    display: none;
    }

    &-link{
      font-size: 12px;
      font-weight: $extra-bold;
      text-transform: uppercase;
    }

    .black-background {
      display: inline-block;
      background-color: $black;
      color: $white;
      padding: 15px 30px;
    }

    .red-background {
      display: inline-block;
      background-color: $main;
      color: $white;
      padding: 15px;
      margin-top: -5px;
      margin-right: -5px;
    }
  }

  &__arrow {
    justify-self: end;
    transform: scale(1);
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 80px;
      height: auto;

      @media (max-width: 800px) {
        width: 40px;
      }
    }
  }

  &__image {
    position: absolute;
    filter: brightness(0.90);
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 1700px;
    height: 80%;
    object-fit: cover;
    object-position: center;
    margin: 0 auto;
    border-radius: 0 0 666px 666px;
    opacity: 0.7;

    @media (max-width: 1800px) {
      max-width: 1500px;
    }

    @media (max-width: 1100px) {
      height: 70%;
    }
  }
}
