.services {
  .steps-two {
    counter-reset: list-two;
    padding: 70px 0 30px;

    .container {
      display: flex;
      flex-direction: column;
    }

    &__paragraph {
      max-width: 70%;
      color: $light-gray;
      font-size: 24px;

      @media (max-width:1800px) {
        font-size: 20px;
      }

      @media (max-width:1300px) {
        font-size: 18px;
      }

      @media (max-width:1000px) {
        max-width: 100%;
      }
    }

    &__container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 50px;

      @media (max-width:1000px) {
        flex-wrap: wrap;
        row-gap: 65px;
      }
    }

    &__element {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 30px;
      width: calc(100% / 3);

      @media (max-width:1000px) {
        width: 50%;
      }

      @media (max-width:600px) {
        width: 100%;
      }

      // exlude first and fourth element from this rule but in smaller resolution add element in fourth element

      &:not(:first-of-type, :nth-of-type(4))::before {
        @include arrow-list;

        top: 30%;
        left: -6%;

        @media (max-width:1000px) {
          left: -8%;
        }

        @media (max-width:600px) {
          left: 50%;
          top: -30%;
          transform: translateX(-50%) rotate(90deg);
        }
      }

      &:nth-of-type(4)::before {
        @media (max-width: 1000px) {
          @include arrow-list;

          top: 30%;
          left: -8%;

        }

        @media (max-width:600px) {
          left: 50%;
          top: -30%;
          transform: translateX(-50%) rotate(90deg);
        }
      }

      &:nth-of-type(3)::before {
        @media (max-width: 1000px) {
          display: none;
        }

        @media (max-width:600px) {
          display: block;
          left: 50%;
          top: -30%;
          transform: translateX(-50%) rotate(90deg);
        }
      }

      &:nth-of-type(5)::before {
        @media (max-width: 1000px) {
          display: none;
        }

        @media (max-width:600px) {
          display: block;
          left: 50%;
          top: -30%;
          transform: translateX(-50%) rotate(90deg);
        }
      }

      &:nth-of-type(3)::after {

        @media (max-width: 1000px) {
          @include arrow-list;
          @include common-arrow-position-mobile;

          transform: rotate(140deg);
        }

        @media (max-width:600px) {
          display: none;
        }
      }

      &:nth-of-type(5)::after {

        @media (max-width: 1000px) {
          @include arrow-list;
          @include common-arrow-position-mobile;

          transform: rotate(140deg);
        }

        @media (max-width:600px) {
          display: none;
        }
      }

      &-container {
        position: relative;
        background-color: $main;
        width: 100px;
        height: 100px;
        border-radius: 666px;
        display: grid;
        place-items: center;

        @media (max-width: 600px) {
          width: 100px;
          height: 100px;
        }

        &::before {
          counter-increment: list-two;
          content: counter(list-two);
          color: $white;
          display: grid;
          place-items: center;
          position: absolute;
          background-color: $black;
          width: 35px;
          height: 35px;
          border-radius: 666px;
          top: 0;
          left: 0;
          font-size: 16px;
        }
      }

      &-img {
        object-fit: contain;
        width: 40%;
      }

      &-description {
        text-align: center;
        font-size: 22px;
        font-weight: $extra-bold;

        @media (max-width:1800px) {
          font-size: 20px;
        }

        @media (max-width:1300px) {
          font-size: 18px;
        }

        @media (max-width:1000px) {
          font-size: 16px;
        }
      }
    }
  }
}
