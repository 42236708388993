.about {
  .values-desktop {
    padding: 50px 0;
    background-color: $black;
    position: relative;

    @media (max-width: 1000px) {
      display: none;
    }

    &::before {
      content: '';
      position: absolute;
      background-color: black;
      right: 5%;
      top: 50%;
      width: 200px;
      height: 200px;
      border-radius: 666px;
      z-index: 1;

      @media (max-width: 1400px) {
        width: 150px;
        height: 150px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      background-color: $darker-gray;
      right: 0;
      transform: translateX(40%);
      top: 50px;
      width: 1100px;
      height: 1100px;
      border-radius: 666px;

      @media (max-width: 1400px) {
        width: 650px;
        height: 650px;
      }

      @media (max-width: 1200px) {
        width: 600px;
        height: 600px;
      }
    }

    &__row {
      display: flex;
      position: relative;
      z-index: 2;
      transform: scale(0.85);

      @media (max-width: 1400px) {
        transform: scale(0.55);
      }

      &-one {
        justify-content: center;
        width: 30%;
        margin: 0 auto;
      }

      &-two {
        justify-content: space-between;
        align-items: flex-end;
        margin-right: 100px;
        margin-left: -100px;
        margin-top: -50px;

        @media (max-width: 1400px) {
          margin-left: -30px;
          margin-top: -230px;
        }

        @media (max-width: 1200px) {
          margin-left: -30px;
          margin-top: -200px;
        }

        @media (max-width: 1100px) {
          margin-top: -150px;
        }
      }

      &-three {
        justify-content: center;
        column-gap: 1%;
        align-items: flex-start;
        margin-top: -50px;

        @media (max-width: 1400px) {
          margin-top: -260px;
        }

        @media (max-width: 1200px) {
          margin-top: -220px;
        }

        @media (max-width: 1100px) {
          margin-top: -180px;
        }
      }
    }

    &__one {
      margin-bottom: -150px;
      position: relative;

      @media (max-width: 1800px) {
        margin-bottom: -80px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -150px;
        width: 90px;
        height: 90px;
        background-color: $main;
        border-radius: 666px;
      }
    }

    &__two {

      @media (max-width: 1400px) {
        transform: translateX(-150px);
      }
    }

    &__three {

      @media (max-width: 1400px) {
        transform: translate(-100px, -100px);
      }
    }

    &__four {
      align-self: flex-start;
      position: relative;

      @media (max-width: 1400px) {
        transform: translateX(150px);
      }

      &::before {
        content: '';
        position: absolute;
        top: 40%;
        left: -150px;
        width: 35px;
        height: 35px;
        background-color: $main;
        border-radius: 666px;
      }

      &::after {
        content: '';
        position: absolute;
        top: -150px;
        left: 50%;
        width: 40px;
        height: 40px;
        background-color: $main;
        border-radius: 666px;
      }
    }

    &__five {
      @media (max-width: 1400px) {
        transform: translateX(-100px);
       }
    }

    &__seven {
      align-self: center;
    }

    &__eight {
      align-self: flex-end;

      @media (max-width: 1400px) {
        transform: translateX(-100px);
       }
    }
  }
}
