.footer{
  padding: 30px 0;
  background-color: $black;
  color: $white;

  @media (max-width: 700px) {
    padding: 0 0 50px;
  }

  &__contact {
    display: grid;
    grid-template-columns: 40% 40%;
    align-items: center;
    padding: 30px 0;
    justify-content: center;
    gap: 30px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  }

  &__heading {
    font-size: 24px;

    @media (max-width: 900px) {
      font-size: 20px;
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;
    font-size: 22px;
    text-transform: lowercase;
    color: $white;
    padding: 15px 30px;
    transition: all 0.3s;

    @media (max-width: 900px) {
      padding: 10px 25px;
      font-size: 20px;
      display: flex;
      justify-content: center;
    }

    &-img {
      display: inline;
      margin-left: 30px;
      width: auto;
      transform: rotate(-90deg);
    }

    &:hover {
      background-color: $main;
      border-color: $main;
    }
  }

  &__hr {
    border: none;
    height: 2px;
    color: $white;
    background-color: $white;
  }

  &__container {
    padding: 30px 0;
    display: flex;
    gap: 100px;

    @media (max-width: 900px) {
      gap: 50px;
    }

    @media (max-width: 800px) {
      display: grid;
    }
  }

  &__sub-heading {
    font-size: 16px;
    text-transform: uppercase;
    color: $light-gray;
    letter-spacing: 3px;
    margin-bottom: 30px;

    @media (max-width: 800px) {
      margin-bottom: 10px;
    }
  }

  &__company {
    font-size: 18px;
    text-transform: uppercase;
    color: $white;
    letter-spacing: 3px;
    margin-bottom: 10px;

    @media (max-width: 350px) {
      font-size: 15px;
    }
  }

  &__item {
    color: $white;
    display: table;
    text-transform: capitalize;
    padding: 5px 0;
    font-size: 18px;
    transition: color 0.3s;

    &--item {
      text-transform: none;
    }

    &:hover {
      color: $main;
    }

    @media (max-width: 700px) {
      font-size: 18px;
    }
  }

  &__office {
    margin-bottom: 30px;
  }

  &__social {
    flex-grow: 1;
  }

  &__copyright {
    font-weight: $regular;
    font-size: 14px;
    color: $light-gray;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 15px;
    padding-top: 20px;
  }

  &__scroll-to-top-button {
    margin-left: 30px;
    width: 20px;
    height: 20px;
    background-color: $main;
    border-radius: 666px;
    padding: 15px;
    transform: rotate(180deg);
  }

  &__privacy {
    color: $white;
    text-transform: capitalize;

    &:hover {
      color: $main;
    }
  }
}

.cookie {
  &__banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $main;
    color: $white;
    z-index: 666;
  }

  &__container {
    font-size: 16px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 20px;
    gap: 10px;

    @media (max-width: 700px) {
      grid-template-columns: 1fr;
      justify-items: start;
      padding: 10px;
      font-size: 12px;
    }

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  &__button {
    cursor: pointer;
    background-color: $white;
    color: $black;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    font-weight: $bold;
    border: 0;
    padding: 15px 30px;
    text-align: center;
    border-radius: 666px;
    margin: 10px 0;
    text-decoration: none;
    transition: all 0.3s;
    text-transform: uppercase;
    font-family: $text;
    @include user-select-none;

    @media (max-width: 700px) {
      padding: 10px 20px;
      font-size: 14px;
    }

    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}
