.error-page {
  min-height: 100vh;
  background-color: $darker-gray;
  color: $white;
  text-align: center;

  &__container {
    padding-top: 200px;
  }

  &__heading {
    color: $main;
    font-size: 150px;

    @media (max-width: 500px) {
      font-size: 100px;
    }
  }

  &__text {
    font-size: 35px;
    font-family: $text;
    font-weight: $extra-bold;
    margin: 20px 0 40px;

    @media (max-width: 500px) {
      font-size: 25px;
    }
  }

  .button-main {
    padding: 15px 50px;
    font-size: 20px;

    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}
