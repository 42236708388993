.portfolio  {
  .live {
    position: relative;

    &__container {
      position: relative;
      z-index: 1;
    }

    &__image {
      border-radius: 20px;
      aspect-ratio: 2/1;
      object-fit: cover;
      object-position: center;
      display: block;

      @media (max-width: 900px) {
        aspect-ratio: 1/1;
      }
    }

    &__see {
      position: absolute;
      top: 70%;
      left: 30%;
      transform: translate(-50%, -30%) rotate(-10deg);
      background-color: $main;
      display: flex;
      color: $white;
      font-weight: $extra-bold;
      gap: 50px;
      align-items: center;
      padding: 20px 50px;
      text-transform: uppercase;
      font-size: 20px;
      transition: 0.5s;

      &:hover {
        transform: scale(1.05) translate(-50%, -25%) rotate(-10deg);
      }

      @media (max-width: 900px) {
        font-size: 16px;
        font-weight: $bold;
        padding: 15px 25px;
      }

      img {
        transform: rotate(-90deg);
        display: block;
        width: 40px;
        aspect-ratio: auto;

        @media (max-width: 900px) {
          width: 20px;
        }
      }
    }
  }
}
