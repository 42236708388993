.about {
  .management {
    padding: 100px 0;

    .container {
      position: relative;
    }

    &__dot {
      position: absolute;
      width: 70px;
      height: 70px;

      &-one {
        top: -240%;
        left: -80px;

        @media (max-width: 1800px) {
          top: -270%;
          left: -90px;
        }

        @media (max-width: 1400px) {
          top: -230%;
          left: -90px;
        }

        @media (max-width: 1300px) {
          top: -200%;
          left: -90px;
        }

        @media (max-width: 1000px) {
          top: -150%;
          left: 0;
        }

        @media (max-width: 600px) {
          top: -140%;
          left: 15%;
        }

        @media (max-width: 500px) {
          top: -130%;
        }
      }

      &-two {
        top: -200%;
        left: 10.5%;

        @media (max-width: 1800px) {
          top: -235%;
          left: 9.5%;
        }

        @media (max-width: 1400px) {
          top: -190%;
          left: 9.5%;
        }

        @media (max-width: 1300px) {
          top: -160%;
          left: 9.5%;
        }

        @media (max-width: 1000px) {
          top: -125%;
          left: 20.5%;
        }

        @media (max-width: 600px) {
          top: -115%;
          left: 70%;
        }

        @media (max-width: 500px) {
          top: -105%;
        }
      }

      &-three {
        top: -260%;
        left: 29%;

        @media (max-width: 1800px) {
          top: -300%;
        }

        @media (max-width: 1400px) {
          top: -260%;
        }

        @media (max-width: 1300px) {
          top: -230%;
        }

        @media (max-width: 1000px) {
          top: -200%;
          left: 46.5%;
        }

        @media (max-width: 600px) {
          top: -90%;
          left: 5%;
        }

        @media (max-width: 500px) {
          top: -80%;
        }
      }

      &-four {
        top: -245%;
        left: 66%;

        @media (max-width: 1800px) {
          top: -280%;
        }

        @media (max-width: 1400px) {
          top: -240%;
        }

        @media (max-width: 1300px) {
          top: -210%;
        }

        @media (max-width: 1000px) {
          top: -160%;
          left: 72.5%;
        }

        @media (max-width: 600px) {
          top: -150%;
          left: 75%;
        }

        @media (max-width: 500px) {
          top: -65%;
        }
      }

      &-five {
        top: -260%;
        left: 100%;

        @media (max-width: 1800px) {
          top: -260%;
        }

        @media (max-width: 1400px) {
          top: -220%;
        }

        @media (max-width: 1300px) {
          top: -190%;
        }

        @media (max-width: 1000px) {
          top: -120%;
          left: 90%;
        }

        @media (max-width: 600px) {
          display: none;
        }
      }
    }

    @media (max-width: 1000px) {
      padding: 50px 0;
    }

    &__heading {
      @include section-heading;

      color: $black;
      text-align: center;
      padding-bottom: 15px;
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 1000px) {
        justify-content: space-around;
        gap: 45px;
        flex-wrap: wrap;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        row-gap: 22px;
      }
    }

    &__img {
      position: relative;

      picture img {
        border-radius: 268px;
        object-fit: cover;
        width: 395px;
        height: 545px;

        @media (max-width: 1800px) {
          border-radius: 198px;
          width: 294px;
          height: 408px;
        }
      }
    }

    &__ln {
      position: absolute;
      display: block;
      bottom: 20px;
      right: 20px;
      width: 70px;
      height: 70px;
      z-index: 99;
      transition: transform 0.3s;

      @media (max-width: 1800px) {
        bottom: 10px;
        right: 10px;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    &__name {
      position: absolute;
      bottom: 90px;
      left: 15px;
      font-size: 29px;
      z-index: 1;
      width: fit-content;
      background-color: $main;
      color: $white;
      font-weight: $extra-bold;
      padding: 10px 20px;

      @media (max-width: 1800px) {
        font-size: 21px;
        left: 5px;
      }
    }

    &__position {
      bottom: 63px;
      z-index: 2;
      position: absolute;
      width: fit-content;
      background-color: $black;
      color: $white;
      font-weight: $extra-bold;
      padding: 9px 7px;
      left: 55px;
      font-size: 14px;

      @media (max-width: 1800px) {
        font-size: 11px;
        left: 40px;
      }
    }

    &__img-first {
      & img {
        position: relative;
        z-index: 1;
      }
    }

    &__img-second {
      margin-top: 80px;

      @media (max-width: 1000px) {
        margin-top: 0;
      }
    }

    &__img-third {
      margin-top: -80px;

      @media (max-width: 1000px) {
        margin-top: 0;
      }
    }
  }
}
