@mixin user-select-none {
  -webkit-touch-callout: none;
  user-select: none;
}

@mixin section-heading {
  font-weight: $bold;
  color: $white;
  font-size: 35px;

  @media (max-width: 900px) {
    font-size: 28px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
}

@mixin section-sub-heading {
  font-weight: $bold;
  color: $main;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 16px;

  @media (max-width: 600px) {
    letter-spacing: 2px;
  }
}

@mixin arrow-list {
  content: '';
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 29px;
  background-image: url('/assets/images/theme/shapes/arrow-red.svg');
  background-repeat: no-repeat;
}

@mixin common-arrow-position-mobile {
  top: -40%;
  left: 92%;
}
