* {
  scroll-padding-top: 70px;
}

body, html {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 24px;
  line-height: 1.5;
  scroll-behavior: smooth;
  color: $black;
  background-color: $white;
}

body {
  overflow-y: scroll;
}

.container {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 1800px) {
    max-width: 1200px;
  }

  @media (max-width: 1300px) {
    max-width: calc(100vw - 120px);
  }

  @media (max-width: 500px) {
    max-width: calc(100vw - 30px);
  }
}

::selection {
  background-color: $main;
  color: $white;
}

b, strong {
  font-weight: $bold;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
  font-family: Syne, sans-serif;
}

p{
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $black;

  &:hover {
    text-decoration: none;
  }
}

img{
  width: 100%;
  height: auto;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  margin: 0;
  padding: 0;
}

.body--overflow{
  overflow: hidden;
  touch-action: none;
}

.text-center{
  text-align: center;
}

.main-color {
  color: $main;;
}

button.splide__pagination__page{
  width: 15px!important;
  margin: 0 3px;
  height: 15px!important;
  border-radius: 666px;
  outline: none;
  border: none;
  background-color: $light-gray;
  appearance: none;

  &:hover{
    cursor: pointer;
  }
}

button.is-active{
  background-color: $main;
}

button {
  appearance: none;
  font-size: auto!important;
}

.hidden {
  display: none;
}
