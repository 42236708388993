.services {
  .technologies-mobile {
    display: none;
    position: relative;

    @media (max-width: 1000px) {
      display: block;
      padding: 50px 0;
      position: relative;
      background-image: url("/assets/images/theme/shapes/background-black-line.svg");
      background-color: $black;

      &::before {
        content: '';
        position: absolute;
        background-color: black;
        right: 0;
        transform: translateX(50%);
        top: 55%;
        width: 120px;
        height: 120px;
        border-radius: 666px;
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        background-color: $darker-gray;
        right: 0;
        transform: translateX(50%);
        top: 30%;
        width: 500px;
        height: 500px;
        border-radius: 666px;
      }
    }

    &__title-container {
      width: 70%;
      z-index: 3;
    }

    &__sub-heading {
      color: $main;
      text-transform: uppercase;
      letter-spacing: 5px;

      @media (max-width:1800px) {
        font-size: 20px;
      }

      @media (max-width:1300px) {
        font-size: 18px;
      }
    }

    &__heading {
      margin-top: -20px;
      font-size: 80px;
      font-weight: $bold;
      color: $white;

      @media (max-width:1800px) {
        margin-top: -10px;
        font-size: 62px;
      }

      @media (max-width:1300px) {
        margin-top: -10px;
        font-size: 48px;
      }

      @media (max-width:1000px) {
        margin-top: -10px;
        font-size: 36px;
      }

      @media (max-width:600px) {
        margin-top: -10px;
        font-size: 32px;
      }
    }

    &__row {
      z-index: 2;
      position: relative;
    }

    &__row-two {
      display: flex;
      justify-content: space-between;
      column-gap: 80px;
      padding-top: 30px;
    }

    &__row-three {
      display: flex;
      justify-content: space-between;
    }

    &__row-four {
      display: flex;
      justify-content: space-between;
      max-width: 80%;
      margin: 0 auto;
      padding-top: 10px;
    }

    &__row-five {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      column-gap: 100px;
    }

    &__angular {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-color: $main;
        width: 25px;
        height: 25px;
        border-radius: 666px;
        top: 60%;
        left: -20%;
      }
    }

    &__qa {
      margin-top: -50px;
    }

    &__php {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-color: $main;
        width: 45px;
        height: 45px;
        border-radius: 666px;
        top: 30%;
        left: -30%;
      }
    }
  }
}
