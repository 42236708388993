.message {
  min-height: 100vh;
  background-color: $almost-black;
  color: $white;
  text-align: center;

  &__container {
    padding-top: 200px;
  }

  &__icon {
    width: 150px;
    height: auto;
  }

  &__text {
    margin: 40px 0;
  }

  .button-main {
    font-size: 18px;
  }
}
