.portfolio  {
  .testimonial {
    padding: 50px 0;

    &__container {
      padding: 50px 0;
      display: grid;
      max-width: 72%;
      margin: 0 auto;
      grid-template-columns: 30fr 70fr;
      align-items: center;
      gap: 140px;

      @media (max-width: 1800px) {
        max-width: 80%;
        gap: 100px;
      }

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        max-width: 100%;
        gap: 20px;
        padding: 0;
      }
    }

    &__person {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        height: 70px;
        width: 70px;
        background-image: url("/assets/images/theme/shapes/red-quotes.svg");
        background-repeat: no-repeat;
        background-size: contain;
        top: -20px;
        left: -70px;

        @media (max-width: 900px) {
          display: none;
        }
      }

      @media (max-width: 900px) {
        text-align: center;
      }
    }

    &__image {
      text-align: center;
    }

    &__photo {
      aspect-ratio: 1/1;
      width: 200px;
      border-radius: 666px;
      object-fit: cover;

      @media (max-width: 900px) {
        margin-bottom: 10px;;
      }
    }

    &__name {
      font-weight: $extra-bold;
      font-size: 22px;
    }

    &__position {
      color: $main;
      font-size: 16px;
    }

    &__opinion {
      margin-top: 20px;
      font-size: 20px;
      font-style: italic;
      color: $light-gray;

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    .video-container {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
    }

    .video-container iframe,
    .video-container object,
    .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
