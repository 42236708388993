.career {
  .jobs {
    padding: 100px 0;

    @media (max-width: 900px) {
      padding: 50px 0;
    }

    &__sub-heading {
      @include section-sub-heading;
    }

    &__heading {
      @include section-heading;

      font-size: 30px;
      color: $black;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      margin-top: 50px;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }
    }

    &__offer {
      background-color: $coffee;
      padding: 45px;
      border-radius: 20px;
      display: grid;
      gap: 30px;
      position: relative;
      overflow: hidden;

      @media (max-width: 1300px) {
        padding: 50px;
      }

      @media (max-width: 500px) {
        padding: 20px;
      }
    }

    &__title-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }

    &__title {
      font-size: 30px;
      line-height: 1.3;

      @media (max-width: 1100px) {
        font-size: 24px;
      }

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    &__icon {
      position: absolute;
      bottom: -25px;
      right: -25px;
      filter: grayscale(1);
      opacity: 0.25;
      width: 200px;

      @media (max-width: 600px) {
        width: 150px;
      }
    }

    &__place-wrapper {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      align-items: center;
    }

    &__type-wrapper {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      align-items: center;
    }

    &__marker {
      width: 20px;

      @media (max-width: 600px) {
        width: 15px;
      }
    }

    &__place {
      font-size: 16px;
    }

    &__type {
      font-weight: $bold;
      text-transform: uppercase;
      font-size: 11px;
      padding: 10px 20px;
      border: 2px solid $black;
      border-radius: 666px;

      @media (max-width: 600px) {
        padding: 5px 10px;
      }
    }

    &__cv-wrapper {
      background-color: $main;
      color: $white;
      padding: 30px;
      border-radius: 20px;
      display: grid;
      gap: 50px;

      @media (max-width: 900px) {
        gap: 20px;
        padding: 20px;
      }
    }

    &__cv {
      display: flex;
      gap: 20px;
      align-items: center;
      flex-wrap: wrap;
    }

    &__cv-text{
      font-size: 18px;
    }

    &__cv-mail {
      font-size: 16px;
      font-weight: $bold;
      text-transform: uppercase;
      padding: 10px 20px;
      border-width: 3px;
    }

    &__hidden {
      display: none;

      &-active {
        display: grid;
      }
    }

    &__show-more {
      grid-column-start: 1;
      grid-column-end: 3;
      margin: 30px auto 0;
      width: fit-content;
      font-size: 18px;

      &:hover {
        background-color: $black;
      }

      @media (max-width: 900px) {
        grid-column-end: 2;
      }
    }
  }
}
