.post {
  padding: 200px 0 50px;
  overflow: clip;

  @media (max-width: 900px) {
    padding-top: 150px;
    overflow: hidden;
  }

  @media (max-width: 600px) {
    padding-top: 100px;
  }

  &__container {
    position: relative;
    z-index: 1;
  }

  &__background {
    position: absolute;
    max-width: 1150px;
    width: 85%;
    height: auto;
    background-color: $coffee;
    border-radius: 666px;
    top: -600px;
    right: -10%;
    z-index: -1;
    @include user-select-none;

    @media (max-width: 1800px) {
      top: -400px;
    }

    @media (max-width: 1300px) {
      top: calc(-250px - 10vw);
      right: -5%;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

  &__heading-author {
    display: grid;
    grid-template-columns: 250px auto;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      text-align: center;
    }
  }

  &__heading{
    display: grid;

    @media (max-width: 900px) {
      text-align: center;
    }
  }

  &__author {
    margin-right: 50px;

    @media (max-width: 900px) {
      margin: 0 auto 50px;
    }
  }

  &__author-photo {
    aspect-ratio: 1/1;
    width: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 666px;

    @media (max-width: 900px) {
      width: 150px;
    }
  }

  &__author-name {
    color: $main;
    font-size: 20px;
    font-weight: $extra-bold;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 18px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__date {
    font-size: 16px;
    color: $light-gray;
    font-weight: $bold;
  }

  &__tags {
    font-size: 16px;
    font-weight: $bold;
    text-transform: uppercase;
    margin-right: 30px;

    a {
      color: $light-gray;

      &:not(:first-child) {
        &::before {
          content: '\2219';
          color: $light-gray;
          margin: 0 7px 0 5px;
        }
      }

      &:hover {
        color: $main;
      }
    }
  }

  &__title {
    font-size: 50px;
    margin: 20px 0;
    text-align: left;
    max-width: 80%;

    @media (max-width: 1300px) {
      font-size: 35px;
    }

    @media (max-width: 900px) {
      font-size: 26px;
      max-width: 100%;
    }
  }

  &__introduction {
    font-size: 22px;

    @media (max-width: 900px) {
      text-align: left;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 70fr 30fr;
    margin-top: 80px;
    gap: 50px;

    @media (max-width: 1800px) {
      grid-template-columns: 60fr 40fr;
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      margin-top: 40px;
      gap: 40px;
    }
  }

  &__toc {
    color: $white;
    background-color: $dark-gray;
    border-radius: 20px;
    padding: 40px;
    font-size: 20px;
    margin-bottom: 50px;

    @media (max-width: 900px) {
      display: none;
    }

    a {
      color: $white;
    }

    & > ul > li {
      margin: 25px 0;
    }

    ul {
      list-style: none;
      margin: 10px 0;

      li {
        text-indent: -1em;
        padding-left: 1em;

        &::before {
          content: '\2219';
          margin-right: 10px;
          font-weight: $extra-bold;
          color: $main;
        }

        ul {
          margin-left: 15px;
          list-style: none;

          li::before {
            content: "\2219";
            font-size: 16px;
            font-weight: $regular;
            color: $main;
          }
        }
      }
    }
  }

    &__sticky {
      margin-top: 50px;
      color: $white;
      background-color: $dark-gray;
      border-radius: 20px;
      padding: 5px;
      font-size: 12px;
      margin-bottom: 50px;
      position: sticky;
      top: 70px;

      @media (max-width: 900px) {
        display: none;
      }

      &-mobile {
        display: none;
        @media (max-width: 900px) {
          display: block;
          position: fixed;
          top: 50%;
          transform: translateX(94%) translateY(-50%);
          transition: transform 0.3s;
          right: 0;

          &.active {
            transform: translateX(0) translateY(-50%);
            padding: 10px;
          }

          &-arrow {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-20px, -50%);
            width: 40px;
            height: 40px;
            border-radius: 666px;
            background-color: $dark-gray;
            color: $main;
            display: grid;
            place-items: center;
            font-size: 24px;
          }
        }
      }

      a {
        color: $white;
      }

      &>ul>li {
        margin: 10px 0;
      }

      ul {
        position: relative;
        list-style: none;
        margin: 10px 0;

        li {
          text-indent: -1em;
          padding-left: 1em;

          &::before {
            content: '\2219';
            margin-right: 10px;
            font-weight: $extra-bold;
            color: $main;
          }

          ul {
            margin-left: 15px;
            list-style: none;

            li::before {
              content: '\2219';
              font-size: 16px;
              font-weight: $regular;
              color: $main;
            }
          }
        }
      }
    }

  &__toc-header {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: $bold;
    color: $light-gray;
  }

  &__toc-title {
    font-size: 30px;
    font-weight: $regular;
    margin: 10px 0;

    @media (max-width: 900px) {
      font-size: 24px;
    }
  }

  &__toc--mobile {
    font-size: 18px;
    margin: 30px 0;

    @media (min-width: 900px) {
      display: none;
    }

    @media (max-width: 900px) {
      display: block;
    }

    @media (max-width: 500px) {
      padding: 20px;
    }
  }

  &__more-wrapper {
    background-color: $main;
    border-radius: 20px;
    color: $white;
    padding: 40px;
  }

  &__more {
    margin-left: 1em;
  }

  &__more-header {
    font-size: 30px;
    font-weight: $bold;
    margin-bottom: 25px;

    @media (max-width: 900px) {
      font-size: 24px;
    }
  }

  &__more-link {
    display: block;
    color: $white;
    font-size: 20px;
    text-indent: -2em;
    padding-left: 1em;
    margin: 5px 0;

    &::before {
      content: '';
      margin-right: 10px;
      background-image: url("/assets/images/theme/shapes/arrow-white.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 20px;
      transform: rotate(-90deg);
      display: inline-block;
    }
  }
}

.navigation {
  padding: 50px 0;

  @media (max-width: 900px) {
    padding: 30px;
  }

  &__hr {
    border: none;
    height: 3px;
    color: $black;
    background-color: $black;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    @media (max-width: 900px) {
      padding: 10px;
    }
  }

  &__back {
    display: flex;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 30px;
    font-weight: $bold;
    font-size: 40px;

    &:hover {
      color: $main;

      img {
        filter: invert(37%) sepia(73%) saturate(1201%) hue-rotate(335deg) brightness(97%) contrast(89%);
      }
    }

    @media (max-width: 900px) {
      font-size: 25px;
      gap: 20px;
    }

    @media (max-width: 600px) {
      font-size: 18px;
      gap: 10px;
    }

    img {
      aspect-ratio: auto;
      transform: rotate(90deg);
      width: 40px;

      @media (max-width: 900px) {
        width: 20px;
      }

      @media (max-width: 600px) {
        width: 10px;
      }
    }
  }
}
