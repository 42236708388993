@font-face {
  font-family: Syne;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/syne-v13-latin-ext-700.woff2') format('woff2');
  src: url('../fonts/syne-v13-latin-ext-700.woff') format('woff');
}

@font-face {
  font-family: Syne;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/syne-v13-latin-ext-800.woff2') format('woff2');
  src: url('../fonts/syne-v13-latin-ext-800.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('');
  font-display: swap;
  src: url('../fonts/open-sans-v29-latin-ext-regular.woff2') format('woff2');
  src: url('../fonts/open-sans-v29-latin-ext-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('');
  font-display: swap;
  src: url('../fonts/open-sans-v29-latin-ext-700.woff2') format('woff2');
  src: url('../fonts/open-sans-v29-latin-ext-700.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('');
  font-display: swap;
  src: url('../fonts/open-sans-v29-latin-ext-800.woff2') format('woff2');
  src: url('../fonts/open-sans-v29-latin-ext-800.woff') format('woff');
}
