.portfolio-banner {
  background-color: $coffee;
  padding: 80px 0;
  margin: 30px 0;

  @media (max-width: 800px) {
    margin: 0;
    padding: 50px 0;
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: center;

    @media (max-width: 800px) {
      gap: 20px;
      justify-content: flex-start;
    }
  }

  &__arrow {
    transform: rotate(-90deg);
    background-color: $main;
    width: 50px;
    height: 50px;
    border-radius: 666px;
    padding: 20px;

    @media (max-width: 800px) {
      width: 4vw;
      height: 4vw;
      padding: 10px;
    }
  }

  &__heading {
    font-size: 32px;

    @media (max-width: 1000px) {
      font-size: 5vw;
    }

    span {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background: url("../images/theme/shapes/highlight.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 130%;
        height: 35px;
        margin-top: 8%;
        margin-left: -10%;
      }
    }
  }

  &__mail {
    color: $main;
    font-weight: $extra-bold;
    font-size: 50px;

    @media (max-width: 1000px) {
      font-size: 5vw;
    }
  }
}
