.contact {
  .contact-form {
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 100px;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }

      @media (max-width: 600px) {
        gap: 50px;
      }
    }

    &__left {
      display: grid;

      @media (max-width: 900px) {
        gap: 70px;
      }
    }

    &__badge {
      transform: rotate(-11deg);
      padding: 10px 0;

      @media (max-width: 900px) {
        order: 2;
        transform: rotate(11deg);
      }

      &-background {
        background-color: $main;
        width: 100%;
        display: flex;
        padding: 20px 0;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
        animation: move 10s infinite ease-in-out 5s;

        @media (max-width: 900px) {
          animation: none;
        }
      }

      @keyframes move {
        0%   { transform: translateX(0); }
        10%  { transform: translateX(20px); }
        20%  { transform: translateX(0); }
        100% { transform: translateX(0); }
      }

      &-text {
        text-align: center;
        font-weight: $extra-bold;
        color: $white;
        font-size: 28px;
        text-transform: uppercase;

        @media (max-width: 1000px) {
          font-size: 24px;
        }

        @media (max-width: 600px) {
          font-size: 20px;
        }
      }

      &-arrow {
        height: 30px;
        width: auto;
        transform: rotate(-90deg);
        margin-left: 20px;
      }
    }

    &__director {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 30px;
      position: relative;

      @media (max-width: 900px) {
        order: 1;
        grid-template-columns: auto 1fr;
      }

      @media (max-width: 700px) {
        order: 1;
      }

      @media (max-width: 500px) {
        text-align: center;
        grid-template-columns: 1fr;
      }

      & > div {
        z-index: 1;
      }

      &-photo {
        border-radius: 666px;
        max-width: 250px;

        @media (max-width: 1300px) {
          max-width: 200px;
        }
      }

      &-name {
        font-weight: $extra-bold;
        font-size: 24px;
        color: $white;

        @media (max-width: 600px) {
          font-size: 18px;
        }
      }

      &-position {
        text-transform: uppercase;
        color: $light-gray;
        letter-spacing: 1.5px;
        font-size: 16px;
        font-weight: $bold;

        @media (max-width: 600px) {
          font-size: 14px;
        }
      }

      &-mail {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 20px;

        @media (max-width: 500px) {
          justify-content: center;
        }
      }

      &-ln {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;

        @media (max-width: 500px) {
          justify-content: center;
        }
      }

      &-phone {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;

        @media (max-width: 500px) {
          justify-content: center;
        }
      }

      &-icon {
        width: 35px;
        height: auto;
      }

      &-address {
        color: $main;
        font-size: 18px;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 10px;

        &:hover {
          text-decoration: underline;
        }
      }

      &-background {
        position: absolute;
        top: 0;
        right: -50px;
        height: 100%;
        width: auto;

        @media (max-width: 900px) {
          left: 0;
        }

        @media (max-width: 500px) {
          display: none;
        }
      }
    }

    &__form {
      margin-top: 50px;

      &-heading {
        text-transform: uppercase;
        color: $light-gray;
        letter-spacing: 1.5px;
        font-size: 16px;
        font-weight: $bold;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active,
      input[data-com-onepassword-filled="dark"],
      select[data-com-onepassword-filled="dark"],
      textarea[data-com-onepassword-filled="dark"] {
        box-shadow: 0 0 0 30px $almost-black inset !important;
        -webkit-text-fill-color: $white;
        background-color: $almost-black;
      }
    }
  }
}
