.services {
  .specialist {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;

      @media (max-width: 1000px) {
        flex-direction: column;
        row-gap: 30px;
      }
    }

    &__content {
      width: 55%;
      display: flex;
      flex-direction: column;
      row-gap: 40px;

      @media (max-width: 1000px) {
        width: 100%;
      }
    }

    &__sub-heading {
      color: $main;
      font-weight: $bold;
      text-transform: uppercase;
      letter-spacing: 5px;

      @media (max-width:1800px) {
        font-size: 20px;
      }

      @media (max-width:1300px) {
        font-size: 18px;
      }
    }

    &__heading {
      color: $black;
      font-size: 50px;
      font-weight: $bold;
      line-height: 1.2;

      @media (max-width: 1300px) {
        font-size: 35px;
      }

      @media (max-width: 900px) {
        font-size: 28px;
      }

      @media (max-width: 500px) {
        font-size: 22px;
      }
    }

    &__paragraph {
      flex: 6;
      margin: 20px 0;
      font-size: 24px;
      color: $light-gray;

      @media (max-width: 1300px) {
        font-size: 20px;
      }

      @media (max-width: 500px) {
        font-size: 18px;
      }
    }

    &__grid {
      width: 40%;
      display: grid;
      grid-template-columns: 1fr 265px;
      grid-template-rows: 265px 460px;
      grid-gap: 10px;
      grid-template-areas:
        "img-one shape"
        "img-two img-two"
      ;

      @media (max-width:1800px) {
        grid-template-columns: 1fr 190px;
        grid-template-rows: 190px 360px;
      }

      @media (max-width:1200px) {
        grid-template-columns: 1fr 160px;
        grid-template-rows: 160px 360px;
        grid-gap: 10px;
      }

      @media (max-width: 1000px) {
        width: 100%;
        grid-template-columns: 1fr 266px;
        grid-template-rows: 266px 400px;
      }

      @media (max-width: 600px) {
        width: 100%;
        grid-template-columns: 1fr 200px;
        grid-template-rows: 200px 301px;
      }

      @media (max-width: 500px) {
        width: 100%;
        grid-template-columns: 1fr 160px;
        grid-template-rows: 160px 241px;
      }

      @media (max-width: 400px) {
        width: 100%;
        grid-template-columns: 1fr 140px;
        grid-template-rows: 140px 211px;
      }
    }

    &__img-one {
      grid-area: img-one;
    }

    &__img-two {
      grid-area: img-two;
    }

    &__photo {
      object-fit: cover;
      width: 100%;
      max-height: 100%;
      border-radius: 20px;
    }

    &__shape {
      grid-area: shape;
      display: grid;
      place-items: center;
    }

    &__shape-img {
      width: 80px;
      height: 80px;

      @media (max-width:1200px) {
        width: 50px;
        height: 50px;
      }
    }
  }
}
