.contact {
  background-color: $almost-black;
  overflow: hidden;

  .hero {
    padding: 150px 0 250px;

    @media (max-width: 1800px) {
      padding-bottom: 200px;
    }

    @media (max-width: 800px) {
      padding-bottom: 100px;
    }

    &__container {
      padding: 0 10%;

      @media (max-width: 800px) {
        padding: 0;
      }
    }

    &__heading {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 300px;
        height: 300px;
        background: $main;
        background-repeat: no-repeat;
        background-size: 35%;
        background-image: url("/assets/images/theme/shapes/phone.svg");
        background-position: center;
        animation: circle 10s infinite ease-in;

        @keyframes circle {
          0%   { clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%); }
          25%  { clip-path: polygon(0 0, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%); }
          50%  { border-radius: 50%; }
          100% { clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%); }
        }

        @media (max-width: 1800px) {
          width: 200px;
          height: 200px;
        }

        @media (max-width: 1300px) {
          width: 14vw;
          height: 14vw;
        }

        @media (max-width: 800px) {
          width: 22vw;
          height: 22vw;
        }
      }
    }

    &__title {
      color: $white;
      font-size: 100px;
      font-weight: $extra-bold;
      position: relative;
      z-index: 1;
      text-align: right;
      text-shadow: $heading-shadow;

      @media (max-width: 1800px) {
        font-size: 70px;
      }

      @media (max-width: 1300px) {
        font-size: 5vw;
      }

      @media (max-width: 800px) {
        text-align: right;
        font-size: 8vw;
      }
    }

    &__sub-title {
      display: flex;
      gap: 50px;

      @media (max-width: 700px) {
        gap: 20px;
      }

      &::before {
        content: "";
        flex: 1;
        border-bottom: 10px solid $white;
        margin: auto;
        margin-left: 10%;

        @media (max-width: 700px) {
          border-bottom: 5px solid $white;
        }
      }
    }
  }
}
