@import "utilities/normalize";
@import "utilities/fonts";
@import "utilities/variables";
@import "utilities/mixins";
@import "utilities/global";
@import "utilities/buttons";
@import "utilities/content";

@import "includes/nav/nav";
@import "includes/footer";
@import "includes/clients-slider";
@import "includes/modal";
@import "includes/archives/archive-tags";
@import "includes/blog/blog-loop";
@import "includes/portfolio/portfolio-hero";
@import "includes/portfolio/portfolio-loop";
@import "includes/portfolio/portfolio-banner";

@import "layouts/homepage/hero";
@import "layouts/homepage/about";
@import "layouts/homepage/benefits";
@import "layouts/homepage/services";
@import "layouts/homepage/portfolio";
@import "layouts/homepage/references";
@import "layouts/homepage/numbers";
@import "layouts/homepage/work";
@import "layouts/homepage/blog";

@import "layouts/contact/hero";
@import "layouts/contact/contact-form";
@import "layouts/contact/offices";
@import "layouts/contact/info";

@import "layouts/career/hero";
@import "layouts/career/jobs";
@import "layouts/career/benefits";
@import "layouts/career/hr";
@import "layouts/career/bottom";
@import "layouts/career/bottom/process";
@import "layouts/career/bottom/photos";

@import "layouts/blog/pagination";
@import "layouts/blog/blog-hero";

@import "layouts/shared/opinion";
@import "layouts/shared/form";
@import "layouts/shared/why";

@import "layouts/about/hero";
@import "layouts/about/values-desktop";
@import "layouts/about/values-mobile";
@import "layouts/about/numbers";
@import "layouts/about/team";
@import "layouts/about/offices";
@import "layouts/about/management";
@import "layouts/about/company-desktop";

@import "layouts/services/hero";
@import "layouts/services/scroll";
@import "layouts/services/project-workshops";
@import "layouts/services/expansion";
@import "layouts/services/benefits";
@import "layouts/services/steps";
@import "layouts/services/wide-img";
@import "layouts/services/project-management";
@import "layouts/services/steps-two";
@import "layouts/services/team-augmentation";
@import "layouts/services/specialist";
@import "layouts/services/technologies-desktop";
@import "layouts/services/technologies-mobile";

@import "layouts/templates/plain-text";
@import "layouts/templates/job";
@import "layouts/templates/post";
@import "layouts/templates/error-page";
@import "layouts/templates/project/hero";
@import "layouts/templates/project/aim";
@import "layouts/templates/project/goals";
@import "layouts/templates/project/live";
@import "layouts/templates/project/challenges";
@import "layouts/templates/project/photos";
@import "layouts/templates/project/solution";
@import "layouts/templates/project/testimonial";
@import "layouts/templates/project/navigation";

@import "layouts/message/message";
