.why {
  background-color: $black;
  color: $white;
  padding: 100px 0;
  position: relative;

  @media (max-width: 1000px) {
    padding: 70px 0;
  }

  &__container {
    position: relative;
  }

  &__first-row {
    display: grid;
    grid-template-columns: 60fr 40fr;

    @media (max-width: 1800px) {
      grid-template-columns: 55fr 45fr;
    }

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  &__heading {
    font-size: 35px;
    margin-bottom: 20px;
    grid-column: 1/3;
    max-width: 50%;

    @media (max-width: 1000px) {
      grid-column: 1;
      max-width: 100%;
      font-size: 30px;
    }
  }

  &__text {
    color: $lighter-gray;
    margin: 30px 20px 30px 0;
    font-size: 20px;

    @media (max-width: 1300px) {
      font-size: 18px;
    }
  }

  &__first-row-cards {
    align-content: end;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  &__card {
    background-color: $main;
    border-radius: 30px;
    padding: 35px 20px;
    display: grid;
    align-content: space-between;
    min-height: 220px;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 0);
    will-change: transform;

    @media (max-width: 1300px) {
      min-height: 170px;
      max-height: 230px;

      @media (max-width: 600px) {
        min-height: 140px;
      }
    }

    img {
      height: 40px;
      width: auto;
      will-change: transform;
      animation: off 0.3s;
    }
  }

  &__card.active {
    transform: rotateX(180deg);

    img {
      transform: rotateX(180deg);
      animation: on 0.3s;
    }
  }

  &__card-text {
    font-size: 22px;
    will-change: transform;
    animation: off 0.3s;

    @media (max-width: 1000px) {
      font-size: 18px;
    }
  }

  &__card-text.active {
    transform: rotateX(180deg);
    order: -1;
    animation: on 0.3s;
  }

  @keyframes off {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes on {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  &__second-row {
    display: grid;
    grid-template-columns: 40fr 60fr;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  &__second-cards {
    display: grid;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  &__image {
    border-radius: 30px;
    max-height: 600px;
    object-fit: cover;
    object-position: center;
    display: block;

    @media (max-width: 1300px) {
      max-height: 500px;
    }
  }

  .card--white {
    margin-left: 45%;
    background-color: $coffee;
    color: $black;

    @media (max-width: 1300px) {
      margin-left: 40%;
    }

    @media (max-width: 1000px) {
      margin-left: 0;
    }
  }

  .card--dark-gray {
    margin-right: 45%;
    background-color: $dark-gray;

    @media (max-width: 1300px) {
      margin-right: 40%;
    }

    @media (max-width: 1000px) {
      margin: 0;
    }
  }

  .card--light-gray {
    background-color: $light-gray;
  }
}

.services {
.why {
  &__container {

    @media (max-width: 1800px) {
      max-width: 85%;
      margin: 0 auto;
    }

    @media (max-width: 1100px) {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
}
