.blog-tags {
  padding: 20px 0;
  position: relative;

  &--background {
    background-image: url("/assets/images/theme/shapes/background-white-line.svg");
  }

  @media (max-width: 800px) {
    display: none;
  }

  &__container {
    display: flex;
    gap: 45px 25px;
    flex-wrap: wrap;
  }

  &__link {
    font-size: 20px;
    padding: 8px 35px;
    border-radius: 666px;
    position: relative;

    &:hover {
      color: $main;
    }

    span {
      background-color: $main;
      color: $white;
      display: inline-flex;
      position: absolute;
      top: -20px;
      right: 0;
      border-radius: 666px;
      align-content: center;
      justify-content: center;
      width: 35px;
      height: 35px;
    }

    &--active {
      border: 3px solid $black;

      &:hover {
        color: $black;
      }
    }
  }
}
