.privacy {
  font-size: 16px;

  .container {
    padding: 150px 0 100px;

    @media (max-width: 500px) {
      padding: 70px 0 50px;
    }
  }

  p {
    padding: 10px 0;
  }

  ul {
    margin-left: 25px;

    li {
      padding: 5px 0;
    }
  }

  a {
    overflow-wrap: anywhere;
    color: $main;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 15px 0;
  }

  table {
    margin: 20px 0;
    display: block;
    overflow-x: auto;
  }

  table, th, td {
    border: 1px solid;
    border-collapse: collapse;
    padding: 5px;
  }
}
