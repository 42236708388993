.portfolio  {
  .solution {
    padding: 50px 0;

    &__container {
      display: grid;
      grid-template-columns: 30fr 70fr;
      align-items: center;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        gap: 30px;
      }
    }

    &__badge {
      transform: rotate(-10deg);
      z-index: 1;

      &-background {
        background-color: $main;
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 20px 40px;
        margin-right: -50px;
        position: relative;
        z-index: 2;
        justify-content: center;
        animation: move 10s infinite ease-in-out 5s;
        border-radius: 5px;

        @media (max-width: 900px) {
          margin: 0 auto;
          gap: 15px;
          width: 70%;
          padding: 20px;
          animation: none;
          transform: rotate(20deg);
        }

        @media (max-width: 600px) {
          width: 80%;
        }
      }
    }

    &__idea-icon {
      aspect-ratio: auto;
      width: 70px;
      margin: -15px 0;

      @media (max-width: 600px) {
        width: 55px;
      }
    }

    &__badge-text {
      color: $white;
      text-transform: uppercase;
      font-size: 28px;
      font-weight: $bold;

      @media (max-width: 900px) {
        font-size: 18px;
      }
    }

    &__arrow-icon {
      transform: rotate(-90deg);
      aspect-ratio: auto;
      width: 50px;

      @media (max-width: 900px) {
        width: 25px;
      }
    }

    &__card {
      background-color: $coffee;
      padding: 60px 60px 60px 100px;
      border-radius: 20px;
      position: relative;

      @media (max-width: 900px) {
        padding: 30px;
        font-size: 18px;
      }

      &::before {
        content: '';
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        top: 0;
        right: 0;
        transform: translate(0%, -70%);
        background-image: url('/assets/images/theme/icons/icon-lightbulb.svg');
        width: 150px;
        aspect-ratio: 11/13;

        @media (max-width: 900px) {
          display: none;
        }
      }
    }

    &__text {
      position: relative;
      z-index: 2;

      @media (max-width: 1400px){
        font-size: 20px;
      }

      @media (max-width: 1100px){
        font-size: 18px;
      }
    }
  }
}
