.portfolio {
  overflow: hidden;

  .hero {
    background-color: $dark-gray;
    padding: 150px 0 100px;
    color: $white;

    @media (max-width: 900px) {
      padding: 100px 0;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }
    }

    &__heading {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &__logo {
      width: 40px;
      height: 40px;
      background-color: $white;
      padding: 15px;
      border-radius: 666px;

      @media (max-width: 600px) {
        width: 30px;
        height: 30px;
        padding: 10px;
      }
    }

    &__title {
      font-size: 34px;

      @media (max-width: 900px) {
        font-size: 28px;
      }
    }

    &__info {
      padding: 30px 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 0;
        width: calc(100% + 400px);
        transform: translateX(calc(-100% + 400px));
        background-color: $dark;
        border-radius: 0 666px 666px 0;

        @media (max-width: 1100px) {
          width: calc(100% + 350px);
          transform: translateX(calc(-100% + 350px));
        }

        @media (max-width: 400px) {
          width: calc(100% + 320px);
          transform: translateX(calc(-100% + 320px));
        }
      }
    }

    &__small-heading {
      position: relative;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 16px;
      font-weight: $bold;
    }

    &__date {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 15px 0;
      position: relative;
    }

    &__date-icon {
      width: 30px;
      height: auto;

      @media (max-width: 1400px) {
        width: 25px;
      }

      @media (max-width: 900px) {
        width: 20px;
      }
    }

    &__year {
      font-size: 35px;

      @media (max-width: 1400px) {
        font-size: 30px;
      }

      @media (max-width: 900px) {
        font-size: 28px;
      }
    }

    &__list {
      list-style: none;
      position: relative;
      padding-left: 40px;
      margin: 15px 0;

      &::before {
        content: '';
        background-image: url("/assets/images/theme/shapes/arrow-white.svg");
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(-90deg);
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 23px;

        @media (max-width: 900px) {
          width: 20px;
        }
      }
    }

    &__position {
      font-size: 24px;

      @media (max-width: 1400px) {
        font-size: 20px;
      }
    }

    &__technologies {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 15px 0;
    }

    &__technology {
      background-color: $white;
      color: $black;
      font-weight: $extra-bold;
      font-family: $text;
      font-size: 16px;
      border-radius: 666px;
      padding: 5px 15px;

      @media (max-width: 900px) {
        padding: 5px 10px;
      }
    }

    &__arrow {
      background-color: $main;
      display: inline-block;
      border-radius: 666px;
      padding: 20px;
      margin-top: 40px;

      img {
        display: block;
        width: 40px;
        height: 40px;
      }

      @media (max-width: 900px) {
        display: none;
      }
    }

    &__overview {
      position: relative;
      width: 120%;

      @media (max-width: 900px) {
        margin-top: 30px;
        width: 100%;
      }

      &::before {
        content: '';
        position: absolute;
        top: 30%;
        left: 0%;
        transform: translate(-50%, -50%);
        z-index: 2;
        background-image: url('/assets/images/theme/shapes/red-circle.svg');
        background-size: contain;
        width: 70px;
        height: 70px;

        @media (max-width: 900px) {
          display: none;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 70%;
        transform: translate(-50%, -50%);
        background-image: url('/assets/images/theme/shapes/red-circle.svg');
        background-size: contain;
        width: 40px;
        height: 40px;

        @media (max-width: 900px) {
          display: none;
        }
      }

      img {
        aspect-ratio: 3/2;
        position: relative;
        display: block;
        z-index: 1;
        border-radius: 20px;
      }
    }
  }
}
