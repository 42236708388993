.homepage{
  .portfolio{
    padding: 100px 0;
    background-color: $coffee;

    @media (max-width: 800px) {
      padding: 70px 0;
    }

    &__heading {
      text-align: center;
      font-size: 200px;
      line-height: 1;
      margin-bottom: -60px;

      @media (max-width: 1800px) {
        margin-bottom: -3vw;
        font-size: 12vw;
      }
    }

    &__scroll {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: $bold;
      text-align: center;
      justify-self: center;

      img {
        vertical-align: baseline;
      }

      &-line {
        margin-left: 10px;
      }

      @media (max-width: 900px) {
        display: none;
      }
    }

    .splide__slide {
      text-align: center;
      cursor: url("../images/theme/shapes/eye-cursor.svg"), url("../images/theme/shapes/eye-cursor.svg"), all-scroll;
    }

    &__container{
      align-items: center;
      display: grid;
      grid-template-columns: 20fr 80fr 20fr;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        max-width: 90%;
        margin: 0 auto;
      }
    }

    &__project {
      a {
        cursor: url("../images/theme/shapes/eye-cursor.svg"), url("../images/theme/shapes/eye-cursor.svg"), move;

        &:hover {
          cursor: url("../images/theme/shapes/eye-cursor.svg"), url("../images/theme/shapes/eye-cursor.svg"), move;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__button {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      .button-main {
        @media (max-width: 500px) {
          font-size: 12px;
          padding: 10px 20px;
        }
      }

      @media (max-width: 900px) {
        margin-top: 0;
      }
    }

    &__name {
      position: relative;

      h3 {
        text-align: left;
        font-size: 40px;
        position: absolute;
        line-height: 1;
        top: 50%;
        transform: translate(-50%);
        left: 0;

        @media (max-width: 900px) {
          position: relative;
          transform: none;
          margin-top: -15px;
          text-align: center;
          top: auto;
          left: auto;
        }

        @media (max-width: 500px) {
          margin-top: -5px;
          font-size: 25px;
        }
      }
    }
  }
}

[data-aos="centered-slider"] {
  pointer-events: none;

  &.aos-animate {
    pointer-events: all;
  }
}
