.portfolio-hero {
  overflow: hidden;
  padding: 200px 0 130px;
  position: relative;

  @media (max-width: 800px) {
    padding: 100px 0 80px;
  }

  &__container {
    z-index: 1;
    position: relative;
  }

  &__scroll-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__heading {
    font-size: 80px;
    line-height: 1.2;
    max-width: 80%;
    color: $black;
    font-weight: $extra-bold;
    position: relative;

    @media (max-width: 1800px) {
      font-size: 70px;
    }

    @media (max-width: 1300px) {
      font-size: 6vw;
    }

    span {
      color: $main;
    }
  }

  &__scroll {
    display: grid;
    justify-content: end;
    text-align: right;
    transform: rotate(-7deg);
    font-family: $heading;

    @media (max-width: 800px) {
      display: none;
    }

    &-link{
      font-size: 12px;
      font-weight: $extra-bold;
      text-transform: uppercase;
    }

    .black-background {
      display: inline-block;
      background-color: $black;
      color: $white;
      padding: 15px 30px;
    }

    .red-background {
      display: inline-block;
      background-color: $main;
      color: $white;
      padding: 15px;
      margin-top: -5px;
      margin-right: -5px;
    }
  }

  &__arrow {
    img {
      width: 100px;
      height: auto;

      @media (max-width: 1800px) {
        width: 80px;
      }

      @media (max-width: 800px) {
        width: 40px;
      }
    }
  }

  &__image {
    position: absolute;
    opacity: 0.6;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 1700px;
    height: 80%;
    object-fit: cover;
    object-position: center;
    margin: 0 auto;
    border-radius: 0 0 666px 666px;

    @media (max-width: 800px) {
      height: 70%;
    }
  }
}
