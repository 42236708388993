.blog {
  .pagination {
    padding: 30px 0 70px;
    position: relative;
    background-image: url("/assets/images/theme/shapes/background-white-line.svg");

    &__container {
      text-align: center;
    }

    &__pagination {
      font-family: $heading;
      font-weight: $bold;
      background-color: $white;
      color: $black;
      border: 2px solid $black;
      display: inline-flex;
      border-radius: 666px;
      justify-content: center;
      width: 40px;
      height: 40px;
      transition: 0.3s;

      &--active {
        background-color: $main;
        color: $white;
        border: 1px solid $main;
      }

      &:hover {
        border-color: $main;
        color: $main;
      }
    }
  }
}
