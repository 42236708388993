.opinion {
  padding: 80px 0;
  position: relative;
  overflow: hidden;

  @media (max-width: 900px) {
    padding: 60px 0;
  }

  &__container {
    display: grid;
    grid-template-columns: 30fr 70fr;
    align-items: center;
    gap: 50px;
    position: relative;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      gap: 25px;
    }
  }

  &__photo {
    position: relative;

    @media (max-width: 900px) {
      max-width: 500px;
    }

    img {
      border-radius: 30px;
      max-height: 500px;
      object-fit: cover;
      object-position: center;
    }

    &::before {
      content: '';
      position: absolute;
      height: 70px;
      width: 70px;
      background-image: url("/assets/images/theme/shapes/red-circle.svg");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      left: 50px;
      top: -20px;

      @media (max-width: 900px) {
        height: 50px;
        width: 50px;
        top: -25px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      height: 50px;
      width: 50px;
      background-image: url("/assets/images/theme/shapes/red-circle.svg");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      right: 20px;
      bottom: -30px;

      @media (max-width: 900px) {
        background-image: url("/assets/images/theme/shapes/red-quotes.svg");
        bottom: -20px;
      }
    }
  }

  &__content {
    z-index: 1;
    position: relative;

    &::after{
      content: '';
      position: absolute;
      height: 70px;
      width: 70px;
      background-image: url("/assets/images/theme/shapes/red-quotes.svg");
      background-repeat: no-repeat;
      background-size: contain;
      left: -40px;
      top: -70px;

      @media (max-width: 900px) {
        display: none;
      }
    }
  }

  &__text {
    font-size: 20px;
    font-style: italic;

    @media (max-width: 1300px) {
      font-size: 18px;
    }
  }

  &__name {
    font-weight: $bold;
    font-size: 24px;
    margin-top: 30px;

    @media (max-width: 900px) {
      font-size: 20px;
    }
  }

  &__position {
    font-size: 16px;
    color: $main;
    letter-spacing: 3px;
  }
}
