.contact {
  .info {
    padding: 0 0 50px;
    text-align: center;
    font-size: 16px;
    font-weight: $regular;
    color: $light-gray;

    &__highlight {
      color: $main;
      font-weight: $bold;
    }

    span {
      color: $main;
      margin: 0 10px;
    }

    p {
      margin: 20px 0;
    }
  }
}
