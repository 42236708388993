.homepage {
  .services {
    padding: 80px 0;

    @media (max-width: 1800px) {
      padding: 50px 0;
    }

    &__sub-heading {
      @include section-sub-heading;

      color: $main;
    }

    &__heading {
      @include section-heading;

      color: $black;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 30px 0;
      gap: 30px;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }
    }

    &__text {
      align-self: center;

      @media (max-width: 1800px) {
        font-size: 18px;
      }
    }

    &__card {
      background-color: $coffee;
      border-radius: 20px;
      display: grid;
      grid-template-columns: 80px auto;
      padding: 30px;
      gap: 30px;
      align-items: center;
      transition: transform 0.3s;

      @media (max-width: 500px) {
        grid-template-columns: 1fr;
        text-align: center;
      }

      &:hover {
        transform: scale(1.05);
      }
    }

    &__card-text {
      margin-top: 10px;

      @media (max-width: 1800px) {
        font-size: 18px;
      }
    }

    &__card-heading {
      @media (max-width: 900px) {
        font-size: 22px;
      }
    }

    &__card-icon {
      @media (max-width: 500px) {
        margin: 0 auto;
        max-width: 50px;
      }
    }
  }
}
