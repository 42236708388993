.career {
  .process {
    padding: 150px 0;
    background-color: $white;
    position: relative;

    @media (max-width: 800px) {
      padding: 50px 0;
    }

    &::after {
      content: '';
      position: absolute;
      left: 40%;
      top: 50%;
      transform: translate(-100%, -50%);
      height: 80%;
      width: 700px;
      background-image: url("../images/theme/shapes/coffee-circle.svg");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 0;

      @media (max-width: 1800px) {
        left: 50%;
      }

      @media (max-width: 1300px) {
        left: 55%;
      }

      @media (max-width: 800px) {
        display: none;
      }
    }

    &__slider {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      gap: 100px;
      position: relative;

      @media (max-width: 1300px) {
        gap: 30px;
      }

      @media (max-width: 800px) {
        grid-template-columns: 1fr;
      }

      & > div {
        z-index: 1;
      }

      .splide__pagination {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 800px) {
          align-items: start;
          flex-direction: row;
        }
      }

      button.splide__pagination__page {
        background-color: $coffee;

        &.is-active {
          background-color: $main;
          width: 20px!important;
          height: 20px!important;
        }
      }
    }

    &__scroll {
      display: flex;
      gap: 20px;
      align-items: center;
      writing-mode: vertical-rl;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: $bold;
      justify-self: center;
      font-family: $heading;

      @media (max-width: 800px) {
        display: none;
      }
    }

    &__slide {
      display: grid;
      grid-template-columns: 250px 1fr;
      align-items: center;

      @media (max-width: 1300px) {
        grid-template-columns: 200px 1fr;
      }

      @media (max-width: 800px) {
        grid-template-columns: 1fr;
        text-align: center;
      }
    }

    &__title {
      font-size: 40px;
      font-weight: $extra-bold;
      font-family: $text;
      line-height: 1;
      text-transform: uppercase;

      @media (max-width: 1300px) {
        font-size: 30px;
      }

      @media (max-width: 800px) {
        font-size: 22px;
      }
    }

    &__text {
      margin-top: 40px;
      margin-right: 10%;
      font-size: 22px;

      @media (max-width: 1300px) {
        margin-right: 0;
      }

      @media (max-width: 800px) {
        margin-top: 20px;
        font-size: 18px;
      }
    }

    &__icon {
      width: 200px;
      height: auto;

      @media (max-width: 1300px) {
        width: 180px;
      }

      @media (max-width: 800px) {
        width: 130px;
      }
    }
  }
}
