.career{
  overflow: hidden;

  .hero{
    padding-top: 150px;
    padding-bottom: 50px;
    color: $white;
    background: $almost-black;
    position: relative;

    &__container {
      display: grid;
      grid-template-columns: 40fr 60fr;
      align-items: center;
      padding: 100px 0;
      z-index: 1;
      position: relative;
      max-width: 90%;
      margin: 0 auto;

      @media (max-width: 900px) {
        max-width: 100%;
        grid-template-columns: 1fr;
        text-align: center;
        padding: 0;
      }
    }

    &__circle-heading {
      font-weight: $extra-bold;
      text-transform: uppercase;
      font-size: 24px;
      display: inline-flex;
      position: relative;
      z-index: 1;
      padding: 10%;
      text-align: center;
      margin-left: -10%;

      @media (max-width: 900px) {
        padding: 6%;
        font-size: 16px;
        margin-left: 0;
      }

      &::after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200px;
        height: 200px;
        background: url("/assets/images/theme/shapes/red-circle.svg") no-repeat center;
        background-size: contain;

        @media (max-width: 900px) {
          width: 120px;
          height: 120px;
        }
      }
    }

    &__heading {
      text-align: right;
      font-size: 105px;
      line-height: 0.8;
      text-shadow: $heading-shadow;

      @media (max-width: 1300px) {
        font-size: 85px;
      }

      @media (max-width: 900px) {
        text-align: center;
        font-size: 35px;
        margin: 40px 0;
      }
    }

    &__heading-dot {
      color: $main;
      font-size: 40px;

      @media (max-width: 1300px) {
        font-size: 35px;
      }

      @media (max-width: 900px) {
        font-size: 25px;
      }
    }

    &__heading-small {
      text-align: right;
      font-size: 40px;
      line-height: 1;
      text-transform: uppercase;

      @media (max-width: 1300px) {
        font-size: 35px;
      }

      @media (max-width: 900px) {
        text-align: center;
        font-size: 25px;
      }
    }

    &__scroll {
      display: grid;
      justify-content: center;
      text-align: right;
      transform: rotate(-7deg);
      position: relative;
      z-index: 1;
      font-family: $heading;

      &-link{
        font-size: 12px;
        font-weight: $extra-bold;
        text-transform: uppercase;

        @media (max-width: 600px) {
          font-size: 10px;
        }
      }

      .black-background {
        display: inline-block;
        background-color: $black;
        color: $white;
        padding: 15px 30px;

        @media (max-width: 600px) {
          padding: 10px 15px;
        }
      }

      .red-background {
        display: inline-block;
        background-color: $main;
        color: $white;
        padding: 15px;
        margin-top: -5px;
        margin-right: -5px;

        @media (max-width: 600px) {
          padding: 10px 15px;
        }
      }
    }

    &__image {
      position: absolute;
      top: 0%;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 1700px;
      height: 85%;
      object-fit: cover;
      object-position: center;
      margin: 0 auto;
      border-radius: 0 0 666px 666px;
      opacity: 0.4;

      @media (max-width: 900px) {
        top: -30%;
        width: 100%;
        height: 80%;
        border-radius: 666px;
      }

      @media (max-width: 600px) {
        height: 75%;
      }
    }
  }
}
