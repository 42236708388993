.homepage{
  .references {
    padding: 100px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @media (max-width: 800px) {
      padding: 50px 0;
    }

    &__sub-heading {
      @include section-sub-heading;
    }

    &__heading {
      @include section-heading;

      color: $black;
    }

    &__container {
      display: grid;
      position: relative;
      grid-template-columns: 30fr 70fr;
      gap: 140px;
      align-items: center;
      align-content: space-evenly;
      width: 72%;
      padding: 20px 0 40px;
      margin: 0 auto;

      @media (max-width: 1800px) {
        width: 80%;
        gap: 100px;
      }

      @media (max-width: 1200px) {
        gap: 60px;
      }

      @media (max-width: 1000px) {
        grid-template-columns: 1fr;
        gap: 0;
      }

      @media (max-width: 800px) {
        width: 100%;
      }
    }

    &__content{
      position: relative;
      padding-top: 100px;

      &::before {
        content: '';
        position: absolute;
        height: 80px;
        width: 80px;
        background-image: url("/assets/images/theme/shapes/red-quotes.svg");
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -1;
        top: 30px;
        left: -75px;

        @media (max-width: 1000px) {
          height: 90px;
          width: 90px;
          top: 15px;
          left: 0;
        }

        @media (max-width: 500px) {
          height: 70px;
          width: 70px;
          top: -20px;
          left: 0;
        }
      }

      @media (max-width: 800px) {
        margin: 0 auto;
        font-size: 18px;
      }

      @media (max-width: 500px){
        padding-top: 40px;
      }

      p {
        font-size: 22px;
        font-style: italic;

        @media (max-width: 500px) {
          font-size: 16px;
        }
      }

      h4 {
        margin-top: 20px;
        font-size: 24px;
      }

      h5 {
        font-size: 14px;
        letter-spacing: 2px;
        max-width: 70%;

        @media (max-width: 800px) {
          max-width: 100%;
        }
      }
    }

    &__image {
      margin: 0 auto;

      img{
        border-radius: 666px;
        aspect-ratio: 1;
        object-fit: cover;
        object-position: center;
        max-width: 200px;
      }
    }

    &__slider{
      padding: 0 10px;

      .splide__slide {
        display: flex;
      }

      .splide__arrows {
        position: absolute;
        top: 40%;
        display: flex;
        width: 100%;
        justify-content: space-between;
        z-index: 99;

        @media (max-width: 1000px) {
          display: none;
        }
      }

      .splide__arrow {
        background: none;
        border: none;
        cursor: pointer;

        &--prev {
          transform: rotate(90deg) scale(1.5);
        }

        &--next {
          transform: rotate(-90deg) scale(1.5);
        }
      }

      .splide__pagination {
        display: none;

        @media (max-width: 1000px) {
          display: flex;
        }
      }
    }

    button.is-active {
      background-color: $main;
    }
  }
}
