.services {
  .scroll {
    background-color: $coffee;
    padding: 30px 0;

    &__heading {
      @include section-heading;

      color: $black;
      padding-bottom: 20px;
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      gap: 30px;

      @media (max-width: 900px) {
        flex-direction: column;
        gap: 10px;
      }
    }

    &__button {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      font-weight: $bold;
      border: 0;
      padding: 10px;
      text-align: center;
      border-radius: 666px;
      text-decoration: none;
      transition: all 0.3s;
      text-transform: uppercase;
      width: calc(100% / 3);

      @media (max-width: 900px) {
        margin: 0;
        width: auto;
      }

      @media (max-width: 500px) {
        justify-content: start;
      }

      &-orange {
        background-color: $main;
        color: $white;
        border: 3px solid $main;

        &:hover {
          background-color: transparent;
          color: $black;
          border-color: $black;

          & img {
            filter: invert(1);
          }
        }
      }

      &-transparent {
        background-color: transparent;
        color: $black;
        border: 3px solid $black;

        &:hover {
          background-color: $main;
          color: $white;
          border-color: $main;

          & img {
            filter: invert(1);
          }
        }
      }
    }

    &__button-img {
      width: 25px;
      filter: invert(0);
      transition: all 0.3s;
    }
  }
}
