.homepage {
  .hero {
    background-color: $dark;
    color: $white;
    position: relative;
    overflow: hidden;

    .splide__slide {
      position: relative;
      overflow: hidden;
    }

    .splide__pagination {
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
    }

    &__container {
      display: grid;
      align-items: center;
      padding: 300px 0 250px;

      @media (max-width: 1800px) {
        padding: 170px 0 150px;
      }

      @media (max-width: 900px) {
        grid-template-columns: 90%;
        justify-content: center;
      }
    }

    &__left {
      max-width: 55%;
      position: relative;
      margin-left: 50px;

      @media (max-width: 900px) {
        max-width: 90%;
        margin-left: 10px;
      }

      @media (max-width: 500px) {
        max-width: 100%;
      }
    }

    &__right {
      max-width: 40%;
      justify-self: end;
      text-align: right;
      margin-top: 50px;

      @media (max-width: 900px) {
        max-width: 90%;
        margin-top: 100px;
        margin-bottom: 20px;
      }

      @media (max-width: 500px) {
        max-width: 100%;
      }
    }

    &__circle {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-15%, -50%);
      width: auto;
      height: 150%;
      z-index: 1;

      @media (max-width: 1300px) {
        transform: translate(0%, -50%);
      }

      @media (max-width: 900px) {
        transform: translate(-20%, -50%);
        height: 150%;
      }
    }

    &__half-circle {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-130%, -50%);
      width: auto;
      height: 150%;
      z-index: 1;

      @media (max-width: 1300px) {
        transform: translate(-100%, -50%);
      }

      @media (max-width: 900px) {
        display: none;
      }
    }

    &__heading {
      font-size: 90px;
      line-height: 1.1;
      position: relative;
      z-index: 2;
      text-shadow: $heading-shadow;

      @media (max-width: 1800px) {
        font-size: 75px;
      }

      @media (max-width: 1300px) {
        margin-left: 50px;
        font-size: 65px;
      }

      @media (max-width: 900px) {
        margin-left: 0;
        font-size: 35px;
      }
    }

    &__heading-small {
      margin-top: 20px;
      display: block;
      font-size: 30px;
      text-transform: uppercase;

      @media (max-width: 1800px) {
        font-size: 28px;
      }

      @media (max-width: 900px) {
        margin-top: 10px;
        font-size: 18px;
      }
    }

    &__sub-heading {
      z-index: 2;
      position: relative;
      font-size: 22px;

      @media (max-width: 1800px) {
        font-size: 18px;
      }

      @media (max-width: 900px) {
        font-size: 14px;
      }

      span {
        box-decoration-break: clone;
        background-color: $black;
        box-shadow: 10px 0 0 $black,-10px 0 0 $black;
        padding: 3px 0;
      }
    }

    &__image-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50%;
      overflow: hidden;

      @media (max-width: 900px) {
        width: 100%;
      }
    }

    &__image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      animation: ken-burns 40s ease infinite;
      opacity: 0.4;

      @media (max-width: 900px) {
        opacity: 0.4;
      }
    }

    @keyframes ken-burns {
      0% {
        transform-origin: bottom left;
        transform: scale(1.0);
      }

      100% {
        transform: scale(1.2);
      }
    }

    &__scroll {
      display: grid;
      justify-content: center;
      text-align: right;
      transform: rotate(-7deg);
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;
      z-index: 1;
      font-family: $heading;

      &-link{
        font-size: 10px;
        font-weight: $extra-bold;
        text-transform: uppercase;

        @media (max-width: 900px) {
          font-size: 8px;
        }
      }

      .black-background {
        display: inline-block;
        background-color: $black;
        color: $white;
        padding: 10px 15px;
      }

      .red-background {
        display: inline-block;
        background-color: $main;
        color: $white;
        padding: 10px 15px;
        margin-top: -5px;
        margin-right: -5px;
      }
    }
  }
}
