.job {
  padding: 150px 0 50px;
  overflow: clip;

  @media (max-width: 900px) {
    overflow: hidden;
  }

  @media (max-width: 600px) {
    padding: 100px 0 50px;
  }

  &-offer {
    overflow: hidden;
    position: relative;
  }

  &__background {
    position: absolute;
    max-width: 1150px;
    width: 72%;
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
    border-radius: 666px;
    top: -600px;
    right: -10%;
    z-index: -1;
    opacity: 0.6;

    @media (max-width: 1800px) {
      top: -400px;
    }

    @media (max-width: 1300px) {
      top: calc(-250px - 10vw);
      right: -5%;
    }

    @media (max-width: 800px) {
      width: 80%;
      right: -15%;
      top: -40vw;
    }
  }

  &__container {
    position: relative;
    z-index: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 70%;
    margin-bottom: 30px;

    @media (max-width: 1300px) {
      max-width: 100%;
    }

    @media (max-width: 600px) {
      margin-bottom: 0;
    }
  }

  &__icon {
    width: 150px;
    aspect-ratio: 1 / 1;
    display: block;

    @media (max-width: 800px) {
      width: 60px;
    }
  }

  &__heading {
    font-size: 45px;
    max-width: 80%;
    line-height: 1.2;

    @media (max-width: 800px) {
      font-size: 35px;
    }

    @media (max-width: 500px) {
      font-size: 22px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 70fr 30fr;
    gap: 50px;

    @media (max-width: 1800px) {
      grid-template-columns: 60fr 40fr;
    }

    @media (max-width: 1000px) {
      grid-template-columns: 50fr 50fr;
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  &__form {
    background-color: $dark-gray;
    border-radius: 20px;
    padding: 30px;
    color: $white;
    position: sticky;
    top: 60px;

    @media (max-width: 500px) {
      padding: 20px;
    }

    .form__label {
      margin-left: 0!important;
      font-size: 16px;
    }

    .form__group {
      margin: 0;
    }

    .form__group-file {
      background: $almost-black;
      border-radius: 10px;
      display: inline-block;
      cursor: pointer;
      width: 100%;

      &:hover {
        cursor: pointer;
      }

      input {
        background-color: $almost-black;
        margin: 20px 0;
        border-bottom: 0;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }
      }

      input[type=file]::file-selector-button {
        background-color: $almost-black;
        color: $white;
        padding: 10px 15px;
        border: 2px solid $white;
        border-radius: 666px;
        font-size: 12px;
        margin-right: 0 15px;
      }

      input[type=file]::file-selector-button:hover {
        background-color: $white;
        color: $almost-black;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .form__button {
      padding: 10px 35px;
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 20px;
    }

    .form__arrow {
      height: 25px;
    }

    .form__acceptance {
      font-size: 12px;
    }
  }

  &__sub-title {
    color: $main;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: $bold;
    letter-spacing: 1px;
  }

  &__title {
    font-size: 24px;
    font-weight: $regular;
    margin: 10px 0 30px;

    @media (max-width: 500px) {
      font-size: 22px;
    }
  }

  &__info-header {
    color: $black;
    margin-top: 20px;
    padding: 10px 0;
    font-size: 12px !important;
  }

  &__info-text {
    color: $black;
    padding: 10px 0;
    font-size: 12px !important;
  }

  &__conditions {
    margin-bottom: 40px;
  }

  &__condition {
    display: flex;
    align-items: center;

    @media (max-width: 700px) {
      display: grid;
      text-align: center;
      margin-top: 20px;
    }
  }

  &__condition-title {
    font-weight: $bold;
  }

  &__condition-icon {
    width: 35px;
    aspect-ratio: 1/1;
    margin-right: 15px;

    @media (max-width: 700px) {
      margin: 0 auto;
    }
  }

  &__benefits {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 60px 0 30px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  &__benefit {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__benefit-icon {
    width: 50px;
    aspect-ratio: 1/1;
    margin-right: 15px;
  }

  &__hr {
    margin-bottom: 30px;
  }

  &__people {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }

  &__person {
    display: flex;
    margin: 15px 0;
    gap: 15px;
    align-items: center;
  }

  &__person-link {
    &:hover {
      &::after {
        content: none;
      }
    }
  }

  &__person-photo {
    width: 80px;
    height: 80px;
    border-radius: 666px;
    border: 3px solid $main;
  }

  &__person-name {
    font-size: 20px;
    margin: 0 !important;
    line-height: 1;

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  &__person-mail {
    font-size: 16px;

    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  &__about {
    margin-bottom: 40px;
  }

  &__description {
    h2 {
      margin-top: 40px;
    }
  }
}
