.services {
  .technologies-desktop {
    padding: 170px 0 0;
    position: relative;
    background-image: url("/assets/images/theme/shapes/background-black-line.svg");
    background-color: $black;

    &::before {
      content: '';
      position: absolute;
      background-color: black;
      right: 0;
      transform: translateX(50%);
      top: 50%;
      width: 120px;
      height: 120px;
      border-radius: 666px;
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      background-color: $darker-gray;
      right: 0;
      transform: translateX(50%);
      top: 0;
      width: 1100px;
      height: 1100px;
      border-radius: 666px;

      @media (max-width: 1400px) {
        width: 800px;
        height: 800px;
      }
    }

    @media (max-width: 1000px) {
      display: none;
    }

    .container {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 1400px) {
      padding: 130px 0 0;
    }

    @media (max-width: 1200px) {
      padding: 100px 0 0;
    }

    @media (max-width: 1000px) {
      padding: 50px 0 0;
    }

    &__title-container {
      width: 70%;
      z-index: 3;
    }

    &__sub-heading {
      color: $main;
      text-transform: uppercase;
      letter-spacing: 5px;

      @media (max-width:1800px) {
        font-size: 20px;
      }

      @media (max-width:1300px) {
        font-size: 18px;
      }
    }

    &__heading {
      margin-top: -20px;
      font-size: 80px;
      font-weight: $bold;
      color: $white;

      @media (max-width:1800px) {
        margin-top: -10px;
        font-size: 62px;
      }

      @media (max-width:1300px) {
        margin-top: -10px;
        font-size: 48px;
      }
    }

    &__row {
      z-index: 2;
    }

    &__row-one {
      display: flex;
      justify-content: center;
      width: 21%;
      margin-left: 40%;
      margin-bottom: -10%;

      @media (max-width:1400px) {
        margin-bottom: -16%;
        transform: scale(0.8);
      }
    }

    &__row-two {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;

      @media (max-width:1400px) {
        column-gap: 90px;

      }

      @media (max-width:1400px) {
        column-gap: 150px;
      }
    }

    &__row-three {
      margin-top: -8%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      column-gap: 30px;

      @media (max-width:1400px) {
        margin-top: -19%;
        transform: scale(0.8);
      }
    }

    &__row-four {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      column-gap: 30px;
      margin-left: -100px;

      @media (max-width:1400px) {
        margin-top: -75px;
        transform: scale(0.8);
      }
    }

    &__react {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-color: $main;
        width: 90px;
        height: 90px;
        border-radius: 666px;
        top: 40%;
        transform: translateY(-50%);
        left: -150px;
      }
    }

    &__ruby {
      position: relative;

      @media (max-width: 1400px) {
        transform: scale(0.8);
      }

      &::before {
        content: '';
        position: absolute;
        background-color: $main;
        width: 35px;
        height: 35px;
        border-radius: 666px;
        top: 50%;
        transform: translateY(-50%);
        left: -100px;
      }

      &::after {
        content: '';
        position: absolute;
        background-color: $main;
        width: 50px;
        height: 50px;
        border-radius: 666px;
        top: -100px;
        transform: translateY(-50%);
        left: 60%;

        @media (max-width:1400px) {
          top: -50px;
        }
      }
    }
  }
}
