// Colors
$lighter-gray: #969696;
$light-gray: #717171;
$dark-gray: #333;
$darker-gray: #191919;
$black: #121212;
$almost-black: #222;
$light-black: #141414;
$dark: #2B2B2B;
$white: #FFF;
$main: #EA543C;
$dark-main: #B4311C;
$darker-main: #671C10;
$coffee: #F2EAE2;

// Fonts
$regular: 400;
$bold: 700;
$extra-bold: 800;
$text: 'Open Sans', sans-serif;
$heading: 'Syne', sans-serif;
$heading-shadow: 1px 1px 20px rgba($black, 0.5);
