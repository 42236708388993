.career {
  .hr {
    background-color: $black;
    background-image: url("/assets/images/theme/shapes/background-black-line.svg");
    color: $white;
    padding: 100px 0;
    position: relative;

    @media (max-width: 800px) {
      padding: 70px 0;
    }

    .container {
      position: relative;
    }

    &__background-one {
      position: absolute;
      width: 100px;
      height: 100px;
      top: -100%;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: 800px) {
        display: none;
      }
    }

    &__background-two {
      position: absolute;
      width: 50px;
      height: 50px;
      top: -110%;
      right: 0%;

      @media (max-width: 800px) {
        display: none;
      }
    }

    &__background-three {
      position: absolute;
      width: 30px;
      height: 30px;
      left: 10%;
      top: -70%;
      transform: translateY(-50%);

      @media (max-width: 800px) {
        display: none;
      }
    }

    &__background-four {
      position: absolute;
      width: 50px;
      height: 50px;
      top: -50%;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: 800px) {
        display: none;
      }
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 100px 50px;
      position: relative;

      @media (max-width: 800px) {
        grid-template-columns: 1fr;
        gap: 70px;
      }
    }

    &__header {
      align-self: center;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -80px;
        left: 0;
        background: url("/assets/images/theme/shapes/smile.svg") no-repeat center;
        background-size: contain;
        width: 100px;
        height: 100px;

        @media (max-width: 800px) {
          width: 70px;
          height: 70px;
          bottom: -60px;
        }
      }
    }

    &__heading {
      font-size: 50px;
      font-weight: $bold;

      @media (max-width: 800px) {
        font-size: 30px;
      }
    }

    &__sub-heading {
      font-size: 30px;
    }

    &__name {
      background-color: $main;
      font-weight: $extra-bold;
      display: table;
      font-size: 22px;
      padding: 8px 20px;
      margin-bottom: -15px;
    }

    &__position {
      color: $black;
      background-color: $white;
      font-weight: $extra-bold;
      font-size: 14px;
      text-align: right;
      display: inline-block;
      padding: 5px 15px;
      margin-left: 25px;
    }

    &__name-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &__photo,
    &__photo--hover {
      border-radius: 666px;
      width: 300px;
      height: 400px;
      object-fit: cover;
      object-position: center;

      @media (max-width: 1000px) {
        width: 200px;
        height: 300px;
      }
    }

    &__photo--hover {
      width: 450px;
      display: none;

      @media (max-width: 1000px) {
        width: 350px;
      }
    }

    &__card {
      position: relative;

      &:hover {
        .hr__photo {
          display: none;
        }

        .hr__photo--hover {
          display: inline-block;
        }

        .hr__circle-text {
          display: inline-block;
          padding: 30px 0;
          top: 0%;
          width: auto;
          height: auto;

          span {
            visibility: visible;
            text-transform: uppercase;
          }
        }
      }
    }

    &__first {
      justify-self: center;

      &--rotate {
        transform: rotate(-10deg);
      }
    }

    &__second {
      justify-self: center;

      &--rotate {
        transform: rotate(-7deg);
      }
    }

    &__third {
      justify-self: center;

      &--rotate {
        transform: rotate(7deg);
      }
    }

    &__contact {
      background-color: $main;
      padding: 20px 30px;
      margin: 10px 0 30px 25%;
      display: inline-flex;
      border-radius: 666px;
      color: #fff;
      transform: rotate(-25deg);
      gap: 20px;
      transition: 0.5s;
      border: none;
      font-size: 29px;

      @media (max-width: 800px) {
        transform: none;
        margin-left: 0;
        padding: 15px 20px;
      }

      &:hover {
        transform: scale(1.1) rotate(-20deg);
        cursor: pointer;
      }

      img {
        width: 20px;
        transform: rotate(-90deg);
      }
    }

    &__circle-text {
      background: url("/assets/images/theme/shapes/red-circle.svg") no-repeat center;
      background-size: contain;
      position: absolute;
      border-radius: 666px;
      top: 60%;
      left: 50%;
      display: inline-block;
      text-align: center;
      align-items: center;
      transform: translate(-50%, -50%);
      line-height: 1;
      width: 30px;
      height: 30px;
      transition: all 0.5s;

      span {
        font-size: 16px;
        font-weight: $extra-bold;
        visibility: hidden;
      }
    }
  }
}
